import { Action } from "redux";
import { isType } from "typescript-fsa";
import { shallowCopyObject } from "../core/utils/shallowCopyObject";
import { doTask, setProgress } from "./actions";
import { State } from "./types";

const initialState: State = {
  activeTasks: {}
};

export const reducer = (state: State = initialState, action: Action): State => {
  if (isType(action, doTask.started)) {
    const { taskName } = action.payload;
    if (state.activeTasks[taskName] !== undefined) {
      throw new Error(`Task ${taskName} is already existing.`);
    }
    return {
      ...state,
      activeTasks: {
        ...state.activeTasks,
        [taskName]: { progress: 0 }
      }
    };
  }

  if (isType(action, doTask.done)) {
    const { taskName } = action.payload.params;
    if (state.activeTasks[taskName] === undefined) {
      throw new Error(`Task ${taskName}, supposed to be done, is not existing.`);
    }
    if (state.activeTasks[taskName].progress < 100) {
      throw new Error(
        `Progress for task ${taskName} needs to be 100 before it is supposed to be completed.`
      );
    }
    const activeTasks = shallowCopyObject(state.activeTasks);
    delete activeTasks[taskName];
    return {
      ...state,
      activeTasks
    };
  }

  if (isType(action, setProgress)) {
    const { progress, name } = action.payload;
    const activeTasks = state.activeTasks;
    const activeTask = activeTasks[name];
    if (activeTasks[name] === undefined) {
      throw new Error(`Task ${name}, supposed to be updated, is not existing.`);
    }
    if (progress <= activeTask.progress || progress > 100) {
      throw new Error(
        `Task progress (${progress}) must be larger than the current value (${
          activeTask.progress
        }) and below 100.`
      );
    }
    return {
      ...state,
      activeTasks: {
        ...activeTasks,
        [name]: { ...activeTask, progress }
      }
    };
  }

  return state;
};
