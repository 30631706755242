// tslint:disable:variable-name

import get from "lodash/get";
import setWith from "lodash/setWith";
import { Container } from "pixi.js";
import { DEFAULT_TILE_SIZE } from "../constants";
import {
  Color,
  Point,
  PointMap,
  Size,
  Tile,
  TileMap,
  TileMapConfiguration,
  TileSet,
  ValueMap
} from "../types";
import { erase } from "../utils/erase";
import { isEqualSize } from "../utils/isEqualSize";
import { createHighlightFilter } from "./highlightFilter";
import { createPoint } from "./point";
import { createSize } from "./size";

export const createTileMap = (config: TileMapConfiguration) => {
  let _pixelSize = createSize(0, 0);
  const _tileContainer = new Container();
  let _tileSize = createSize(DEFAULT_TILE_SIZE, DEFAULT_TILE_SIZE);
  const _tileMap = new PIXI.Application({
    antialias: false,
    autoResize: true,
    autoStart: false
  }) as TileMap;
  const _tileZoom = 0;
  let _tilesByName: ValueMap<Tile> = {};
  let _tilesByMapPosition: PointMap<Tile[]>;

  const _refreshSize = () => {
    const { renderer } = _tileMap;
    const widthInTiles = Math.floor(_pixelSize.width / _tileSize.width);
    const heightInTiles = Math.floor(_pixelSize.height / _tileSize.height);
    if (widthInTiles > 0 && heightInTiles > 0) {
      const newSizeInTiles = createSize(widthInTiles, heightInTiles);
      const newSizeInPixels = createSize(
        widthInTiles * _tileSize.width,
        heightInTiles * _tileSize.height
      );
      if (
        !isEqualSize(_tileMap.size.pixels, newSizeInPixels) ||
        !isEqualSize(_tileMap.size.tiles, newSizeInTiles)
      ) {
        _tileMap.size.pixels = newSizeInPixels;
        _tileMap.size.tiles = newSizeInTiles;
        renderer.resize(_tileMap.size.pixels.width, _tileMap.size.pixels.height);
        _tileMap.render();
      }
    }
  };

  const addTile = (tile: Tile) => {
    tile.resize(_tileSize);
    const { mapPosition, name } = tile;
    if (getTile(name)) {
      return;
    }
    _tileMap.isEmpty = false;
    if (!_tileMap.tileSet.useTint) {
      tile.tint = 0xffffff;
    }
    _tileContainer.addChild(tile);
    _tilesByName[name] = tile;
    const { x, y } = mapPosition;
    const tilesAtPosition = get(_tilesByMapPosition, [x, y], []);
    tilesAtPosition.push(tile);
    setWith(_tilesByMapPosition, [x, y], tilesAtPosition, Object);
  };

  const changeBackgroundColor = (color: Color) => {
    if (_tileMap.renderer.backgroundColor !== color.asNumber) {
      _tileMap.renderer.backgroundColor = color.asNumber;
      _tileMap.render();
      return true;
    }
    return false;
  };

  const changeOffset = (offset: Point) => {
    const { x, y } = offset;
    _tileContainer.x = x ? x * _tileSize.width : 0;
    _tileContainer.y = y ? y * _tileSize.height : 0;
    _tileMap.render();
  };

  const changeSize = (pixelSize: Size) => {
    if (_pixelSize !== pixelSize) {
      _pixelSize = pixelSize;
      _refreshSize();
      return true;
    }
    return false;
  };

  const changeTileSet = (tileSet: TileSet) => {
    if (!_tileMap.tileSet || _tileMap.tileSet.name !== tileSet.name) {
      _tileSize = createSize(
        tileSet.tileSize.width + _tileZoom,
        tileSet.tileSize.height + _tileZoom
      );
      _tileMap.isInitialized = false;
      if (config.onUnInitialize) {
        config.onUnInitialize();
      }
      const keys = Object.keys(PIXI.utils.TextureCache);
      for (let i = 0, len = keys.length; i < len; i++) {
        const texture = PIXI.utils.TextureCache[keys[i]];
        if (texture && texture.destroy) {
          texture.destroy(true);
        }
      }
      _tileMap.tileSet = tileSet;
      const { name, textureAtlas } = tileSet;
      _tileMap.loader
        .add([{ name, url: textureAtlas }])
        .on("complete", () => {
          _tileMap.isInitialized = true;
          if (config.onInitialize) {
            config.onInitialize();
          }
        })
        .load();
      _refreshSize();
      return true;
    }
    return false;
  };

  const changeTileZoom = (factor: number = 0) => {
    const newWidth = _tileMap.tileSet.tileSize.width + factor;
    const newHeight = _tileMap.tileSet.tileSize.height + factor;
    if (newWidth !== _tileSize.width || newHeight !== _tileSize.height) {
      _tileSize = createSize(newWidth, newHeight);
      _refreshSize();
      return true;
    }
    return false;
  };

  const clearTiles = () => {
    _tileMap.isEmpty = true;
    _tileContainer.removeChildren();
    _tilesByName = {};
    _tilesByMapPosition = {};
    changeOffset(createPoint(0, 0));
    _tileMap.render();
  };

  const getTile = (name: string): void | Tile => _tilesByName[name];

  const getTilesAt = ({ x, y }: Point) => get(_tilesByMapPosition, [x, y], []);

  const highlight = ({ x, y }: Point, strong: boolean = false) => {
    const tiles = get(_tilesByMapPosition, [x, y]);
    if (!tiles) {
      return;
    }
    for (let i = 0, len = tiles.length; i < len; i++) {
      tiles[i].filters = [createHighlightFilter(strong)];
    }
    _tileMap.render();
  };

  const removeTile = (tile: string | Tile) => {
    const toRemove = typeof tile === "string" ? getTile(tile) : tile;
    if (!toRemove) {
      return;
    }
    _tileContainer.removeChild(toRemove);
    if (_tileContainer.children.length === 0) {
      _tileMap.isEmpty = true;
    }
    delete _tilesByName[toRemove.name];
    const { x, y } = toRemove.mapPosition;
    const atMapPosition = get(_tilesByMapPosition, [x, y]) as Tile[];
    if (atMapPosition.length === 1) {
      erase(_tilesByMapPosition, [x, y]);
    } else {
      const index = atMapPosition.indexOf(toRemove);
      atMapPosition.splice(index, 1);
    }
  };

  const unHighlight = ({ x, y }: Point) => {
    const tiles = get(_tilesByMapPosition, [x, y]);
    if (!tiles) {
      return;
    }
    for (let i = 0, len = tiles.length; i < len; i++) {
      tiles[i].filters = [];
    }
    _tileMap.render();
  };

  _tileMap.stage.addChild(_tileContainer);
  _tileMap.addTile = addTile;
  _tileMap.changeBackgroundColor = changeBackgroundColor;
  _tileMap.changeTileOffset = changeOffset;
  _tileMap.changeSize = changeSize;
  _tileMap.changeTileSet = changeTileSet;
  _tileMap.changeTileZoom = changeTileZoom;
  _tileMap.clearTiles = clearTiles;
  _tileMap.getTile = getTile;
  _tileMap.getTilesAt = getTilesAt;
  _tileMap.highlight = highlight;
  _tileMap.removeTile = removeTile;
  _tileMap.tiles = _tileContainer.children as Tile[];
  _tileMap.size = { pixels: createSize(0, 0), tiles: createSize(0, 0) };
  _tileMap.unHighlight = unHighlight;
  changeBackgroundColor(config.backgroundColor);
  changeTileSet(config.tileSet);
  changeSize(config.pixelSize);
  clearTiles();

  return _tileMap;
};
