import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import unset from "lodash/unset";

export const erase = <T>(object: T, path: Array<number | string>) => {
  unset(object, path);
  while (--path.length && isEmpty(get(object, path))) {
    unset(object, path);
  }
};
