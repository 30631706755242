import { Action } from "redux";
import { isType } from "typescript-fsa";
import { shallowCopyObject } from "../core/utils/shallowCopyObject";
import { addNotification, removeNotification } from "./actions";
import { State } from "./types";

const initialState: State = {};

export const reducer = (state: State = initialState, action: Action): State => {
  if (isType(action, addNotification)) {
    const { notification } = action.payload;
    return {
      ...state,
      [notification.id]: notification
    };
  }

  if (isType(action, removeNotification)) {
    const { id } = action.payload;
    return shallowCopyObject(state, id);
  }

  return state;
};
