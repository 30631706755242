import { createPoint } from "../../core/factories/point";
import { Point } from "../../core/types";
import { calculateAngle } from "../../core/utils/calculateAngle";
import { calculateDistance } from "../../core/utils/calculateDistance";
import { GESTURE_ANGLE_PADDING, GESTURE_ANGLE_TOLERANCE } from "../constants";
import { Direction } from "../enums/Direction";
import { GestureEvent } from "../types";

const getDirectionFromAngle = (angle: number): Direction => {
  const isBetween = (min: number, max: number, diagonal: boolean = false) =>
    diagonal
      ? angle >= min + GESTURE_ANGLE_PADDING && angle < max - GESTURE_ANGLE_PADDING
      : angle >= min - GESTURE_ANGLE_PADDING && angle < max + GESTURE_ANGLE_PADDING;
  if (isBetween(22.5, 67.5, true)) {
    return Direction.DownRight;
  }
  if (isBetween(67.5, 112.5)) {
    return Direction.Down;
  }
  if (isBetween(112.5, 157.5, true)) {
    return Direction.DownLeft;
  }
  if (isBetween(157.5, 202.5)) {
    return Direction.Left;
  }
  if (isBetween(202.5, 247.5, true)) {
    return Direction.UpLeft;
  }
  if (isBetween(247.5, 292.5)) {
    return Direction.Up;
  }
  if (isBetween(292.5, 335.5, true)) {
    return Direction.UpRight;
  }
  return Direction.Right;
};

const getMovedDistance = (touched: Point, previous: void | GestureEvent) =>
  previous
    ? Math.round(
        previous.distance + calculateDistance(previous.x, previous.y, touched.x, touched.y)
      )
    : 0;

const getRelativePoint = (event: MouseEvent | Touch) => {
  const { pageX, pageY, target } = event;
  const { left, top } = (target as HTMLElement).getBoundingClientRect();
  const x = pageX - left < 0 ? 0 : Math.round(pageX - left);
  const y = pageY - top < 0 ? 0 : Math.round(pageY - top);
  return createPoint(x, y);
};

const mapGestureEventToDirection = (touched: Point, previous: void | GestureEvent) =>
  previous
    ? shouldReUsePrevious(touched, previous)
      ? previous.direction
      : getDirectionFromAngle(calculateAngle(previous.x, previous.y, touched.x, touched.y))
    : undefined;

const shouldReUsePrevious = (touched: Point, previous: void | GestureEvent): boolean =>
  previous !== undefined &&
  previous.direction !== undefined &&
  Math.abs(touched.x - previous.x) < GESTURE_ANGLE_TOLERANCE &&
  Math.abs(touched.y - previous.y) < GESTURE_ANGLE_TOLERANCE;

export const createGestureEvent = (
  nativeEvent: MouseEvent | Touch,
  previousEvent?: void | GestureEvent
): GestureEvent => {
  const mousePoint = getRelativePoint(nativeEvent);
  const time = new Date().getTime();
  return {
    direction: mapGestureEventToDirection(mousePoint, previousEvent),
    distance: getMovedDistance(mousePoint, previousEvent),
    startPoint: previousEvent ? previousEvent.startPoint : mousePoint,
    startTime: previousEvent ? previousEvent.startTime : time,
    time,
    ...mousePoint
  };
};
