import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import Button from "../../../core/components/Button";
import Input from "../../../core/components/Input";
import LabeledControl from "../../../core/components/LabeledControl";
import Menu from "../../../core/components/Menu";
import { Setter } from "../../../core/types";
import { normalizeSpace } from "../../../core/utils/normalizeSpace";
import { useDataLifecycle } from "../../../data/hooks/useDataLifecycle";
import { randomSeed } from "../../../data/utils/randomSeed";
import { messages } from "./messages";
import "./styles.css";

interface Props {
  onCancel: VoidFunction;
}

const useSeed = (): [string, Setter<string>] => {
  const [seed, setSeed] = useState(randomSeed());
  const setCleanSeed = useCallback((dirtySeed: string) => setSeed(normalizeSpace(dirtySeed)), [
    setSeed
  ]);
  return [seed, setCleanSeed];
};

const NewGameMenu: React.FunctionComponent<Props> = ({ onCancel }) => {
  const [seed, setSeed] = useSeed();
  const { createSavegame } = useDataLifecycle();
  const handleCreate = useCallback(() => createSavegame({ seed }), [seed]);
  return (
    <Menu id="new-game-menu">
      <LabeledControl label={<FormattedMessage {...messages.labelSeed} />}>
        <Input defaultValue={seed} id="new-game-seed-input" onSubmit={setSeed} />
      </LabeledControl>
      <LabeledControl>
        <Button id="game-menu-create-button" onUse={handleCreate}>
          <FormattedMessage {...messages.createButtonLabel} />
        </Button>
      </LabeledControl>
      <LabeledControl>
        <Button id="game-menu-cancel-button" onUse={onCancel}>
          <FormattedMessage {...messages.backButtonLabel} />
        </Button>
      </LabeledControl>
    </Menu>
  );
};

export default NewGameMenu;
