import React, { useRef } from "react";
import { Url } from "../../../app/enums/Url";
import { FormFactor } from "../../../measuring/enums/FormFactor";
import { useFormFactor } from "../../../measuring/hooks/selectorHooks";
import { useKeyboard } from "../../../userInput/hooks/useKeyboard";
import { useTouch } from "../../../userInput/hooks/useTouch";
import { PanCallback } from "../../../userInput/types";
import { HISTORY } from "../../constants";
import { joinStrings } from "../../utils/joinStrings";
import Header from "../Header";
import LinkButton from "../LinkButton";
import "./styles.css";

interface Props {
  className?: string;
  fullSize?: boolean;
  headline?: string | JSX.Element;
  id: string;
  onEscape?: VoidFunction;
  onRightBorderPan?: PanCallback;
  onRightBorderPanEnd?: PanCallback;
  returnButtonText?: string | JSX.Element;
  returnTo?: Url;
  children: string | JSX.Element | JSX.Element[];
}

interface ChildProps {
  isMobile: boolean;
}

const handleEscape = (url?: string, onEscape?: VoidFunction) => () => {
  if (url) {
    HISTORY.push(url);
  } else if (typeof onEscape === "function") {
    onEscape();
  }
};

const Page: React.FunctionComponent<Props> = ({
  children,
  className,
  fullSize,
  headline,
  id,
  onEscape,
  onRightBorderPan,
  onRightBorderPanEnd,
  returnButtonText,
  returnTo
}) => {
  const page = useRef<null | HTMLDivElement>(null);
  const formFactor = useFormFactor();
  useTouch(page, { onRightBorderPan, onRightBorderPanEnd });
  useKeyboard({ onEscape: handleEscape(returnTo, onEscape) });
  return (
    <div
      className={joinStrings([
        "page",
        className,
        formFactor === FormFactor.Phone ? "compact" : "",
        fullSize ? "full-size" : ""
      ])}
      id={id}
      ref={page}
    >
      {headline ? <Header id="title">{headline}</Header> : <></>}
      <div id="content">{children}</div>
      {returnButtonText && returnTo ? (
        <LinkButton autoFocus={true} target={returnTo} id="button-return">
          {returnButtonText}
        </LinkButton>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Page;
