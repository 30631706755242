import React, { ReactText } from "react";
import { SelectOption, Setter } from "../../types";
import { joinStrings } from "../../utils/joinStrings";
import Option from "./Option";

interface Props {
  backgroundColor: string;
  borderColor: string;
  highlighted: number;
  isExpanded: boolean;
  onOptionClick: Setter<ReactText>;
  onOptionHover: Setter<number>;
  options: SelectOption[];
  textColor: string;
}

const Options: React.FunctionComponent<Props> = ({
  backgroundColor,
  borderColor,
  highlighted,
  isExpanded,
  onOptionClick,
  onOptionHover,
  options,
  textColor
}) => (
  <div
    className={joinStrings(["select-options", isExpanded ? "expanded" : ""])}
    style={{
      backgroundColor,
      borderColor
    }}
  >
    {options.map((option, index) => {
      const color = option === options[highlighted] ? borderColor : textColor;
      const handleClick = () => onOptionClick(option.value);
      const handleMouseEnter = () => onOptionHover(index);
      return (
        <Option
          color={color}
          key={option.value}
          label={option.label}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
        />
      );
    })}
  </div>
);

export default Options;
