import React, { useCallback } from "react";
import { Redirect } from "react-router";
import Page from "../../../core/components/Page";
import Spinner from "../../../core/components/Spinner";
import { joinStrings } from "../../../core/utils/joinStrings";
import { useHasSavegame, useIsSynching } from "../../../data/hooks/selectorHooks";
import { useIsMobile } from "../../../measuring/hooks/selectorHooks";
import { CommandName } from "../../../userInput/enums/CommandName";
import { useSecondaryControls } from "../../../userInput/hooks/useSecondaryControls";
import { useTrigger } from "../../../userInput/hooks/useTrigger";
import { Url } from "../../enums/Url";
import MessagePanel from "../MessagePanel";
import PrimaryControls from "../PrimaryControls";
import SecondaryControls from "../SecondaryControls";
import StatusPanel from "../StatusPanel";
import WorldTileMap from "../WorldTileMap";
import "./styles.css";

const GamePage: React.FunctionComponent = () => {
  const [hasSecondaryControls, toggleSecondaryControls] = useSecondaryControls();
  const [, trigger] = useTrigger();
  const hasSavegame = useHasSavegame();
  const isCacheSyncing = useIsSynching();
  const isMobile = useIsMobile();
  const triggerSecondaryToggle = useCallback(
    () => trigger(CommandName.ToggleSecondaryControls, toggleSecondaryControls),
    [toggleSecondaryControls, trigger]
  );
  return !hasSavegame ? (
    <Redirect to={Url.Menu} />
  ) : (
    <>
      {isCacheSyncing ? <Spinner /> : <></>}
      <Page
        fullSize={true}
        id="page-game"
        onRightBorderPanEnd={hasSecondaryControls ? undefined : triggerSecondaryToggle}
        returnTo={Url.Menu}
      >
        <div
          id="game"
          className={joinStrings([
            isMobile ? "small" : "",
            hasSecondaryControls ? "with-secondary" : ""
          ])}
        >
          <StatusPanel />
          <WorldTileMap />
          <SecondaryControls />
          <MessagePanel />
          <PrimaryControls />
        </div>
      </Page>
    </>
  );
};

export default GamePage;
