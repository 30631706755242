import { ComponentType } from "../enums/ComponentType";
import { Appearance, Coordinates, Entity, Memory, Motion, Perception } from "../types";

const getComponent = <T>(entity: Entity, componentType: ComponentType) =>
  entity ? (entity.components[componentType] as T) : undefined;

export const getAppearance = (entity: Entity) =>
  getComponent<Appearance>(entity, ComponentType.Appearance);

export const getCoordinates = (entity: Entity) =>
  getComponent<Coordinates>(entity, ComponentType.Coordinates);

export const getIsActor = (entity: Entity) => getComponent<boolean>(entity, ComponentType.IsActor);

export const getMemory = (entity: Entity) => getComponent<Memory>(entity, ComponentType.Memory);

export const getMotion = (entity: Entity) => getComponent<Motion>(entity, ComponentType.Motion);

export const getPerception = (entity: Entity) =>
  getComponent<Perception>(entity, ComponentType.Perception);
