import { Action } from "redux";
import { isType } from "typescript-fsa";
import { createSize } from "../core/factories/size";
import { createSavegame, loadSavegame, resetCache, syncCache } from "./actions";
import { State } from "./types";

const initialState: State = {
  cellTypeCache: {},
  chunkSize: 0,
  coordinatesCache: {},
  entityCache: {},
  isSynched: false,
  isSynching: false,
  mapMeta: { id: 0, size: createSize(0, 0) },
  playerId: 0,
  seed: "",
  timestamp: 0,
  turn: 0
};

export const reducer = (state: State = initialState, action: Action): State => {
  if (isType(action, createSavegame.done)) {
    return {
      ...state,
      ...action.payload.result.meta
    };
  }

  if (isType(action, loadSavegame.done)) {
    return {
      ...state,
      ...action.payload.result.meta
    };
  }

  if (isType(action, resetCache)) {
    return initialState;
  }

  if (isType(action, syncCache.done)) {
    return {
      ...state,
      isSynched: true,
      isSynching: false,
      ...action.payload.result
    };
  }

  if (isType(action, syncCache.failed)) {
    return {
      ...state,
      isSynched: false,
      isSynching: false
    };
  }

  if (isType(action, syncCache.started)) {
    return {
      ...state,
      isSynching: true
    };
  }

  return state;
};
