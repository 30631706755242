import React from "react";
import { joinStrings } from "../../utils/joinStrings";
import "./styles.css";

interface Props {
  align?: "start" | "middle" | "end";
  children: JSX.Element | JSX.Element[];
  className?: string;
  id?: string;
  vertical?: boolean;
}

const ItemBar: React.FunctionComponent<Props> = ({ align, children, className, id, vertical }) => (
  <div className={joinStrings(["item-bar", className, vertical ? "vertical" : "", align])} id={id}>
    {children}
  </div>
);

ItemBar.defaultProps = {
  align: "start",
  vertical: false
};

export default ItemBar;
