import { TableName } from "../enums/TableName";
import { openDb } from "./open";

export const write = async <T>(table: TableName, key: number | string, value: T) => {
  const db = await openDb();
  const tx = db.transaction(table, "readwrite");
  const store = tx.objectStore(table);
  const count = await store.count(key);
  if (count === 0) {
    await store.add(value, key);
  } else {
    await store.put(value, key);
  }
};
