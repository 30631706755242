import colorDefinitions from "../definitions/colors.json";
import { ColorName } from "../enums/ColorName";
import { ColorThemeName } from "../enums/ColorThemeName";
import { createColor } from "../factories/color";
import { ColorDefinitions, ColorsJson } from "../types";

export const createColors = () => {
  const result: ColorDefinitions = {};
  for (const themeName of Object.values(ColorThemeName)) {
    result[themeName] = {};
    const themeColors = (colorDefinitions as ColorsJson)[themeName];
    if (!themeColors) {
      throw new Error(`Theme '${themeName}' is not defined in 'core/definitions/colors.json'`);
    }
    for (const colorName of Object.values(ColorName)) {
      const color = themeColors[colorName];
      if (!color) {
        throw new Error(
          `Color '${colorName}' of theme '${themeName}' is not defined in 'core/definitions/colors.json'`
        );
      }
      result[themeName][colorName] = createColor(color);
    }
  }

  return result;
};
