import React from "react";
import { KeyEvent } from "../../userInput/types";
import { isKeyEvent } from "./isKeyEvent";
import { isSyntheticEvent } from "./isSyntheticEvent";

export const stopEvent = <T extends HTMLElement>(
  e?:
    | void
    | React.KeyboardEvent<T>
    | React.FocusEvent<T>
    | React.MouseEvent<T>
    | React.TouchEvent<T>
    | KeyEvent
    | KeyboardEvent
    | MouseEvent
    | TouchEvent
    | FocusEvent
) => {
  if (isKeyEvent(e)) {
    const { originalEvent } = e;
    if (originalEvent) {
      originalEvent.preventDefault();
      originalEvent.stopImmediatePropagation();
      originalEvent.stopPropagation();
    }
  } else if (isSyntheticEvent(e)) {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  } else if (e !== undefined) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
  }
};
