import { defineMessages } from "react-intl";

export const messages = defineMessages({
  backButtonLabel: {
    defaultMessage: "Back to Menu",
    description: "The label of the back-button",
    id: "SettingsPage.backButtonLabel"
  },
  headline: {
    defaultMessage: "Settings",
    description: "The headline of the settings page",
    id: "SettingsPage.headline"
  },
  labelColorTheme: {
    defaultMessage: "Color theme",
    description: "The label of the color theme select",
    id: "SettingsPage.labelColorTheme"
  },
  labelKeyMap: {
    defaultMessage: "Key map",
    description: "The label of the key map select",
    id: "SettingsPage.labelKeyMap"
  },
  labelTileSet: {
    defaultMessage: "Tile set",
    description: "The label of the tile set select",
    id: "SettingsPage.labelTileSet"
  },
  labelVibration: {
    defaultMessage: "Vibration",
    description: "The label of the vibration select",
    id: "SettingsPage.labelVibration"
  }
});
