import React from "react";
import { FormattedMessage } from "react-intl";
import { ScreenOverlay } from "../../../core/components/ScreenOverlay";
import { ColorName } from "../../../core/enums/ColorName";
import { useNotifications } from "../../../notifications/hooks/useNotifications";
import { useColorStrings } from "../../../settings/hooks/colorHooks";
import "./styles.css";

const Notifications: React.FunctionComponent = () => {
  const [background, warning] = useColorStrings(ColorName.Background, ColorName.Warning);
  const { hasErrors, notifications } = useNotifications();
  return notifications.length > 0 ? (
    <ScreenOverlay>
      <div
        id="notifications-content"
        style={{
          background: hasErrors ? warning : background
        }}
      >
        {notifications.map(({ id, text }) => (
          <FormattedMessage key={id} {...text} />
        ))}
      </div>
    </ScreenOverlay>
  ) : (
    <></>
  );
};

export default Notifications;
