import React, { ReactText } from "react";
import { FormattedMessage } from "react-intl";
import EnumSelect from "../../../core/components/EnumSelect";
import LabeledControl from "../../../core/components/LabeledControl";
import LinkButton from "../../../core/components/LinkButton";
import Menu from "../../../core/components/Menu";
import Page from "../../../core/components/Page";
import { ColorThemeName } from "../../../core/enums/ColorThemeName";
import { TileSetName } from "../../../core/enums/TileSetName";
import { Setter } from "../../../core/types";
import { useIsMobile } from "../../../measuring/hooks/selectorHooks";
import {
  useColorThemeName,
  useKeyMapName,
  useTileSetName,
  useVibrationIntensity
} from "../../../settings/hooks/stateHooks";
import { KeyMapName } from "../../../userInput/enums/KeyMapName";
import { VibrationIntensity } from "../../../userInput/enums/VibrationIntensity";
import { Url } from "../../enums/Url";
import { messages } from "./messages";
import "./styles.css";

const SettingsPage: React.FunctionComponent = () => {
  const [colorThemeName, updateColorThemeName] = useColorThemeName();
  const [keyMapName, updateKeyMapName] = useKeyMapName();
  const [tileSetName, updateTileSetName] = useTileSetName();
  const [vibrationIntensity, updateVibrationIntensity] = useVibrationIntensity();
  const isMobile = useIsMobile();
  return (
    <Page
      headline={<FormattedMessage {...messages.headline} />}
      id="page-settings"
      returnTo={Url.Menu}
    >
      <Menu>
        <LabeledControl label={<FormattedMessage {...messages.labelColorTheme} />}>
          <EnumSelect
            enumObject={ColorThemeName}
            id="page-settings-select-color-theme"
            onSubmit={updateColorThemeName as Setter<ReactText>}
            value={colorThemeName}
          />
        </LabeledControl>
        {!isMobile ? (
          <LabeledControl label={<FormattedMessage {...messages.labelKeyMap} />}>
            <EnumSelect
              enumObject={KeyMapName}
              id="page-settings-select-key-map"
              onSubmit={updateKeyMapName as Setter<ReactText>}
              value={keyMapName}
            />
          </LabeledControl>
        ) : (
          <></>
        )}
        <LabeledControl label={<FormattedMessage {...messages.labelTileSet} />}>
          <EnumSelect
            enumObject={TileSetName}
            id="page-settings-select-tile-set"
            onSubmit={updateTileSetName as Setter<ReactText>}
            value={tileSetName}
          />
        </LabeledControl>
        {isMobile ? (
          <LabeledControl label={<FormattedMessage {...messages.labelVibration} />}>
            <EnumSelect
              enumObject={VibrationIntensity}
              id="page-settings-select-vibration"
              onSubmit={updateVibrationIntensity as Setter<ReactText>}
              value={vibrationIntensity}
            />
          </LabeledControl>
        ) : (
          <></>
        )}
        <LabeledControl>
          <LinkButton id="page-settings-back-button" target={Url.Menu}>
            <FormattedMessage {...messages.backButtonLabel} />
          </LinkButton>
        </LabeledControl>
      </Menu>
    </Page>
  );
};

export default SettingsPage;
