import React from "react";
import { Omit } from "react-router";
import { Url } from "../../../app/enums/Url";
import { HISTORY } from "../../constants";
import Button, { Props as ButtonProps } from "../Button";

interface Props extends Omit<ButtonProps, "onUse"> {
  target: Url;
}

const handleUse = (target: Url) => () => HISTORY.push(target);

const LinkButton: React.FunctionComponent<Props> = ({ target, ...otherProps }) => (
  <Button onUse={handleUse(target)} {...otherProps} />
);

export default LinkButton;
