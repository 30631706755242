import { MapMetaMap } from "../../api/types";
import { MapId, MapMeta } from "../../model/types";
import { clear } from "../database/clear";
import { read } from "../database/read";
import { write } from "../database/write";
import { TableName } from "../enums/TableName";

export const clearMapMeta = async () => {
  await clear(TableName.MapMeta);
};

export const readMapMeta = async (mapId: MapId) => {
  return await read<MapMeta>(TableName.MapMeta, mapId);
};

export const writeMapMeta = async (mapMetaMap: MapMetaMap) => {
  const mapIds = Object.keys(mapMetaMap);
  for (let i = 0, length = mapIds.length; i < length; i++) {
    const mapId = Number(mapIds[i]);
    await write(TableName.MapMeta, mapId, mapMetaMap[mapId]);
  }
};
