import { createColor } from "../factories/color";
import { Color } from "../types";
import { scaleNumber } from "./scaleNumber";

export const addOpacity = (color: Color, decimalOpacity: number): Color => {
  if (decimalOpacity < 0 || decimalOpacity > 1) {
    throw new Error("Number not valid");
  }
  const integerOpacity = scaleNumber(decimalOpacity, 0, 1, 0, 255);
  let hexString = integerOpacity.toString(16);
  if (hexString.length % 2) {
    hexString = "0" + hexString;
  }
  return createColor(`${color.asString}${hexString}`);
};
