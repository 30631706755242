import { Omit } from "lodash";
import { API_URL, ENDPOINT_WORLDS } from "./constants";
import { WorldParameters, WorldResponse } from "./types";

const fetchCors = async <T>(pathParts: string[], options?: Omit<RequestInit, "mode">) =>
  await fetch([API_URL, ...pathParts].join("/"), { ...options, mode: "cors" })
    .then((response: any) => response.json())
    .then((json: any) => {
      delete json._links;
      return json as T;
    })
    .catch(() => undefined);

export const getWorld = async ({ seed }: WorldParameters) =>
  await fetchCors<WorldResponse>([ENDPOINT_WORLDS, seed]);
