export enum SpriteName {
  CellFloorIndoors = "cell-floor-indoors",
  CellWallThick = "cell-wall-thick",
  EntityHuman = "entity-human",
  IconArrowDown = "icon-arrow-down",
  IconArrowLeft = "icon-arrow-left",
  IconArrowRight = "icon-arrow-right",
  IconArrowUp = "icon-arrow-up",
  IconMenu = "icon-menu",
  IconMinus = "icon-minus",
  IconPlus = "icon-plus",
  IconZero = "icon-zero"
}
