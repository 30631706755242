import find from "lodash/find";
import { useSelector } from "../../core/hooks/useSelector";
import { NotificationType } from "../enums/NotificationType";
import { getNotifications } from "../selectors";

export const useNotifications = () => {
  const notifications = useSelector(getNotifications);
  const hasErrors = find(notifications, { type: NotificationType.Error }) !== undefined;
  return { notifications, hasErrors };
};
