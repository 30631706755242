import { useCallback, useEffect, useRef } from "react";
import { useSetIsResizing } from "../../measuring/hooks/actionHooks";
import { ToggleName } from "../../settings/enums/ToggleName";
import { SECONDARY_CONTROLS_TOGGLE_RESIZE_TIMEOUT } from "../constants";
import { useToggle } from "./useToggle";

export const useSecondaryControls = (): [boolean, VoidFunction] => {
  const timeout = useRef<null | NodeJS.Timeout>(null);
  const [isToggled, toggle] = useToggle(ToggleName.SecondaryControls);
  const setIsResizing = useSetIsResizing();
  const doToggle = useCallback(() => {
    setIsResizing(true);
    toggle();
    timeout.current = setTimeout(
      () => setIsResizing(false),
      SECONDARY_CONTROLS_TOGGLE_RESIZE_TIMEOUT
    );
  }, [setIsResizing, toggle]);
  useEffect(() => {
    return () => {
      if (timeout.current !== null) {
        clearTimeout(timeout.current);
      }
    };
  }, []);
  return [isToggled, doToggle];
};
