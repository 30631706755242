import definitions from "../definitions/entities.json";
import { EntityType } from "../enums/EntityType";
import { EntityTemplates } from "../types";

export const createEntityTemplates = (): EntityTemplates => {
  for (const type of Object.values(EntityType)) {
    const definition = (definitions as EntityTemplates)[type];
    if (!definition) {
      throw new Error(
        `Entity definition '${type}' is not defined in 'entity/definitions/entities.json'`
      );
    }
  }
  return definitions as EntityTemplates;
};
