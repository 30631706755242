import React, { useState } from "react";
import { SPINNER_INTERVAL } from "../../constants";
import { useInterval } from "../../hooks/useInterval";
import { ScreenOverlay } from "../ScreenOverlay";
import "./styles.css";

const nodes = [
  <span key="0">loading</span>,
  <span key="1">loading.</span>,
  <span key="2">loading..</span>,
  <span key="3">loading...</span>
];

const Spinner: React.FunctionComponent = () => {
  const [step, setStep] = useState(0);
  useInterval(() => setStep(prev => (prev === nodes.length - 1 ? 0 : prev + 1)), SPINNER_INTERVAL);
  return <ScreenOverlay>{nodes[step]}</ScreenOverlay>;
};

export default Spinner;
