import nanoid from "nanoid";
import React from "react";
import { useColorStrings } from "../../../settings/hooks/colorHooks";
import { useTileSet } from "../../../settings/hooks/selectorHooks";
import { ColorName } from "../../enums/ColorName";
import { SpriteName } from "../../enums/SpriteName";
import { joinStrings } from "../../utils/joinStrings";
import "./styles.css";

interface Props {
  className?: string;
  color?: ColorName;
  sprite: SpriteName;
}

const SpriteIcon: React.FunctionComponent<Props> = ({ className, color, sprite }) => {
  const [floodColor] = useColorStrings(color || ColorName.Text);
  const tileSet = useTileSet();
  const filterId = `color-filter-${nanoid()}`;
  return (
    <div className={joinStrings(["icon", "icon-sprite", className])}>
      <svg height={`${tileSet.tileSize.height}px`} width={`${tileSet.tileSize.width}px`}>
        <defs>
          <filter id={filterId}>
            <feFlood floodColor={floodColor} result="flood" />
            <feComposite
              in="SourceGraphic"
              in2="flood"
              operator="arithmetic"
              k1="1"
              k2="0"
              k3="0"
              k4="0"
            />
          </filter>
        </defs>
        <image
          filter={`url(#${filterId})`}
          height={`${tileSet.tileSize.height}px`}
          href={`${tileSet.spritesPath}${sprite}.png`}
          width={`${tileSet.tileSize.width}px`}
        />
      </svg>
    </div>
  );
};

export default SpriteIcon;
