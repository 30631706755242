export enum CommandName {
  MapZoomDecrease = "map-zoom-decrease",
  MapZoomIncrease = "map-zoom-increase",
  MapZoomReset = "map-zoom-reset",
  MoveDown = "move-down",
  MoveDownLeft = "move-down-left",
  MoveDownRight = "move-down-right",
  MoveLeft = "move-left",
  MoveRight = "move-right",
  MoveUp = "move-up",
  MoveUpLeft = "move-up-left",
  MoveUpRight = "move-up-right",
  ToggleSecondaryControls = "toggle-secondary-controls"
}
