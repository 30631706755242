import { useCallback } from "react";
import { useDispatch } from "redux-react-hook";
import { WorldParameters } from "../../api/types";
import * as actions from "../actions";

export const useDataLifecycle = () => {
  const dispatch = useDispatch();
  const createSavegame = useCallback(
    (worldParameters: WorldParameters) => dispatch(actions.createSavegame.started(worldParameters)),
    []
  );
  return {
    createSavegame
  };
};
