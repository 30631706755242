import React from "react";
import { joinStrings } from "../../utils/joinStrings";

interface Props {
  children: number | string | JSX.Element | JSX.Element[];
  className?: string;
  id?: string;
  level?: 1 | 2 | 3 | 4 | 5 | 6;
}

const Header: React.FunctionComponent<Props> = ({ className, children, id, level }) =>
  React.createElement(`h${level}`, {
    children,
    className: joinStrings(["header", className]),
    id
  });

Header.defaultProps = {
  level: 1
};

export default Header;
