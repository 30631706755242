import { useCallback } from "react";
import { useDispatch } from "redux-react-hook";
import { useSelector } from "../../core/hooks/useSelector";
import * as actions from "../actions";
import { getActiveControl } from "../selectors";

type ReturnType = [
  void | string,
  (controlId?: string, forceChange?: boolean) => void,
  (settings: { isGuaranteed?: boolean; isLocked?: boolean }) => void
];

export const useActiveControl = (): ReturnType => {
  const dispatch = useDispatch();
  const activeControl = useSelector(getActiveControl);
  const setActiveControl = useCallback(
    (controlId?: string, forceChange?: boolean) =>
      dispatch(actions.setActiveControlId({ controlId, forceChange })),
    []
  );
  const updateSettings = useCallback(
    (settings: { isGuaranteed?: boolean; isLocked?: boolean }) =>
      dispatch(actions.updateActiveControlSettings(settings)),
    []
  );
  return [activeControl.controlId, setActiveControl, updateSettings];
};
