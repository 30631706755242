import React from "react";
import { Omit } from "react-router";
import { enumToMapArray } from "../../utils/enumToMapArray";
import Select, { Props as SelectProps } from "../Select";

interface Props extends Omit<SelectProps, "options"> {
  enumObject: any;
}

const createOptions = (enumObject: any) =>
  enumToMapArray(enumObject).map(({ value }) => ({ value, label: value }));

const EnumSelect: React.FunctionComponent<Props> = ({ enumObject, ...otherProps }) => (
  <Select options={createOptions(enumObject)} {...otherProps} />
);

export default EnumSelect;
