import { useSelector } from "../../core/hooks/useSelector";
import {
  getFont,
  getIsInitialized,
  getKeyCommands,
  getThemeColors,
  getTileSet,
  getToggles,
  getVibrationIntensityValue
} from "../selectors";

export const useFont = () => useSelector(getFont);

export const useIsInitialized = () => useSelector(getIsInitialized);

export const useKeyCommands = () => useSelector(getKeyCommands);

export const useThemeColors = () => useSelector(getThemeColors);

export const useTileSet = () => useSelector(getTileSet);

export const useToggles = () => useSelector(getToggles);

export const useVibrationIntensityValue = () => useSelector(getVibrationIntensityValue);
