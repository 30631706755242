import isEmpty from "lodash/isEmpty";
import { createSelector } from "reselect";
import { State } from "../core/types";
import { MODULE_NAME } from "./constants";
import { Tasks } from "./types";

export const getActiveTasks = (state: State): Tasks => state[MODULE_NAME].activeTasks;

export const getHasActiveTasks = createSelector(
  getActiveTasks,
  activeTasks => !isEmpty(activeTasks)
);
