export enum ColorName {
  // UI colors
  Background = "background",
  Text = "text",
  Primary = "primary",
  Secondary = "secondary",
  Warning = "warning",
  // Game colors
  Black = "black",
  Blue = "blue",
  Brown = "brown",
  Clay = "clay",
  Conifer = "conifer",
  Cyan = "cyan",
  Eggplant = "eggplant",
  Gold = "gold",
  Granite = "granite",
  Grass = "grass",
  Green = "green",
  Jungle = "jungle",
  Lake = "lake",
  Leaf = "leaf",
  Magenta = "magenta",
  Maroon = "maroon",
  Ocean = "ocean",
  Olive = "olive",
  Orange = "orange",
  Pink = "pink",
  Purple = "purple",
  Red = "red",
  Salmon = "salmon",
  Sand = "sand",
  Seaweed = "seaweed",
  Silver = "silver",
  Sky = "sky",
  Steel = "steel",
  Teal = "teal",
  White = "white",
  Wine = "wine",
  Yellow = "yellow"
}
