import "normalize.css";
import React from "react";
import "react-app-polyfill/ie11";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import { Router } from "react-router";
import { StoreContext } from "redux-react-hook";
import App from "./app/components/App";
import { HISTORY, REDUX_STORE, SAGA_MIDDLEWARE } from "./core/constants";
import { saga } from "./core/saga";
import "./styles.css";

SAGA_MIDDLEWARE.run(saga);

ReactDOM.render(
  <IntlProvider locale="en">
    <Router history={HISTORY}>
      <StoreContext.Provider value={REDUX_STORE}>
        <App />
      </StoreContext.Provider>
    </Router>
  </IntlProvider>,
  document.getElementById("root") as HTMLElement
);
