import { call, put } from "redux-saga/effects";
import { readMeta } from "../../persistence/accessors/meta";
import * as taskActions from "../../tasks/actions";
import { TaskName } from "../../tasks/enums/TaskName";
import * as dataActions from "../actions";

export function* loadSavegame() {
  yield put(dataActions.loadSavegame.started());
  yield put(taskActions.doTask.started({ taskName: TaskName.LoadSavegame }));
  const meta = yield call(readMeta);
  if (meta) {
    yield put(dataActions.loadSavegame.done({ result: { meta } }));
  } else {
    yield put(dataActions.loadSavegame.failed({ error: {} }));
  }
  yield put(taskActions.setProgress({ name: TaskName.LoadSavegame, progress: 100 }));
  yield put(taskActions.doTask.done({ params: { taskName: TaskName.LoadSavegame }, result: {} }));
  yield put(dataActions.syncCache.started());
}
