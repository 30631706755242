import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { createColors } from "./factories/colors";
import { createFonts } from "./factories/fonts";
import { createReduxStore } from "./factories/reduxStore";
import { createTileSets } from "./factories/tileSets";

export const APP_TITLE = "Apuleius";

export const COLORS = createColors();

export const DEFAULT_TILE_SIZE = 16;

export const FONTS = createFonts();

export const MODULE_NAME = "core";

export const SPINNER_INTERVAL = 500;

export const TILE_SETS = createTileSets();

// need to be exported in this order to avoid circular dependencies
export const HISTORY = createBrowserHistory();

export const SAGA_MIDDLEWARE = createSagaMiddleware();

export const REDUX_STORE = createReduxStore();
