export const shallowCopyObject = <T extends { [key: string]: any }>(
  original: T,
  omitKey?: string
): T => {
  const copy = {} as T;
  const keys = Object.keys(original);
  for (let i = 0, len = keys.length; i < len; i++) {
    const key = keys[i];
    copy[key] = original[key];
  }
  if (omitKey) {
    delete copy[omitKey];
  }
  return copy;
};
