import { openDb as open, UpgradeDB } from "idb";
import { DATABASE_NAME, DATABASE_VERSION } from "../constants";
import { TableName } from "../enums/TableName";

const upgradeDb = (db: UpgradeDB) => {
  for (const tableName of Object.values(TableName)) {
    db.createObjectStore(tableName);
  }
};

export const openDb = async () => await open(DATABASE_NAME, DATABASE_VERSION, upgradeDb);
