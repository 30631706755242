import { FontName } from "../enums/FontName";
import { Size, TileSet } from "../types";

const metadata = (baseName: string) => {
  const baseUrl = `${process.env.PUBLIC_URL}/spriteSheets/${baseName}/`;
  const spriteSheet = `${baseUrl}${baseName}.png`;
  const spritesPath = `${baseUrl}/sprites/`;
  const textureAtlas = `${baseUrl}${baseName}.json`;
  return { baseUrl, spriteSheet, spritesPath, textureAtlas };
};

export const createTileSet = (
  spriteSheetName: string,
  matchingFont: FontName,
  tileSize: Size,
  useTint: boolean
): TileSet => ({
  matchingFont,
  name: spriteSheetName,
  tileSize,
  useTint,
  ...metadata(spriteSheetName)
});
