import { ColorName } from "../../core/enums/ColorName";
import { Color } from "../../core/types";
import { FALLBACK_COLOR_NAME } from "../constants";
import { useThemeColors } from "./selectorHooks";

const useColors = (colorNames: ColorName[]) => {
  const colors = useThemeColors();
  const length = colorNames.length;
  const colorValues = new Array<Color>(length);
  for (let i = 0; i < length; i++) {
    colorValues[i] = colors ? colors[colorNames[i]] : colors[FALLBACK_COLOR_NAME];
  }
  return colorValues;
};

export const useColorStrings = (...colorNames: ColorName[]) => {
  const colors = useColors(colorNames);
  return colors.map(color => color.asString);
};

export const useColorNumbers = (...colorNames: ColorName[]) => {
  const colors = useColors(colorNames);
  return colors.map(color => color.asNumber);
};
