import { Color } from "../types";

const getBase = (htmlColor: string) => htmlColor.slice(1);

const toNumber = (colorString: string) => Number(`0x${getBase(colorString)}`);

export const createColor = (color: string): Color => ({
  asNumber: toNumber(color),
  asString: color
});
