import { Action } from "redux";
import { isType } from "typescript-fsa";
import { createSize } from "../core/factories/size";
import { setClientSize, setIsResizing } from "./actions";
import { State } from "./types";

const initialState: State = {
  clientSize: createSize(
    document.documentElement!.clientWidth,
    document.documentElement!.clientHeight
  ),
  isResizing: false
};

export const reducer = (state: State = initialState, action: Action) => {
  if (isType(action, setClientSize)) {
    return { ...state, clientSize: action.payload.clientSize };
  }

  if (isType(action, setIsResizing)) {
    return { ...state, isResizing: action.payload.isResizing };
  }

  return state;
};
