import { VibrationIntensity } from "../enums/VibrationIntensity";
import { numberFromVibrationIntensity } from "./numberFromVibrationIntensity";

export const vibrate = (vibrationIntensity?: VibrationIntensity) => {
  if (
    window.navigator.vibrate &&
    vibrationIntensity &&
    vibrationIntensity !== VibrationIntensity.Off
  ) {
    window.navigator.vibrate(numberFromVibrationIntensity(vibrationIntensity));
  }
};
