import { combineReducers } from "redux";
import { MODULE_NAME as API_MODULE_NAME } from "../api/constants";
import { reducer as apiReducer } from "../api/reducer";
import { MODULE_NAME as DATA_MODULE_NAME } from "../data/constants";
import { reducer as dataReducer } from "../data/reducer";
import { MODULE_NAME as MEASURING_MODULE_NAME } from "../measuring/constants";
import { reducer as measuringReducer } from "../measuring/reducer";
import { MODULE_NAME as NOTIFICATIONS_MODULE_NAME } from "../notifications/constants";
import { reducer as notificationsReducer } from "../notifications/reducer";
import { MODULE_NAME as SETTINGS_MODULE_NAME } from "../settings/constants";
import { reducer as settingsReducer } from "../settings/reducer";
import { MODULE_NAME as TASKS_MODULE_NAME } from "../tasks/constants";
import { reducer as tasksReducer } from "../tasks/reducer";
import { MODULE_NAME as USER_INPUT_MODULE_NAME } from "../userInput/constants";
import { reducer as userInputReducer } from "../userInput/reducer";
import { State } from "./types";

export const reducer = combineReducers<State>({
  [API_MODULE_NAME]: apiReducer,
  [MEASURING_MODULE_NAME]: measuringReducer,
  [NOTIFICATIONS_MODULE_NAME]: notificationsReducer,
  [DATA_MODULE_NAME]: dataReducer,
  [SETTINGS_MODULE_NAME]: settingsReducer,
  [TASKS_MODULE_NAME]: tasksReducer,
  [USER_INPUT_MODULE_NAME]: userInputReducer
});
