import actionCreatorFactory from "typescript-fsa";
import { WorldParameters } from "../api/types";
import { CellTypeMap, CoordinatesMap, EntityMap, MapMeta } from "../model/types";
import { MODULE_NAME } from "./constants";
import { Meta } from "./types";

const action = actionCreatorFactory(MODULE_NAME);

export const createSavegame = action.async<WorldParameters, { meta: Meta }>("CREATE_SAVEGAME");

export const loadSavegame = action.async<undefined, { meta: Meta }>("LOAD_SAVEGAME");

export const resetCache = action("RESET_CACHE");

export const syncCache = action.async<
  undefined,
  {
    cellTypeCache: CellTypeMap;
    coordinatesCache: CoordinatesMap;
    entityCache: EntityMap;
    mapMeta: MapMeta;
  }
>("SYNC_CACHE");
