import React from "react";
import Button from "../../../core/components/Button";
import ItemBar from "../../../core/components/ItemBar";
import { SpriteName } from "../../../core/enums/SpriteName";
import { CommandName } from "../../../userInput/enums/CommandName";
import { useSecondaryControls } from "../../../userInput/hooks/useSecondaryControls";

const PrimaryControls: React.FunctionComponent = () => {
  const [hasSecondary, toggleSecondary] = useSecondaryControls();
  return (
    <ItemBar align="end" id="primary-controls">
      <Button id={CommandName.ToggleSecondaryControls} onUse={toggleSecondary}>
        {hasSecondary ? SpriteName.IconArrowRight : SpriteName.IconArrowLeft}
      </Button>
    </ItemBar>
  );
};

export default PrimaryControls;
