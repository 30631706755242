import { Omit } from "lodash";
import { Meta } from "../../data/types";
import { META_KEY } from "../constants";
import { clear } from "../database/clear";
import { read } from "../database/read";
import { write } from "../database/write";
import { TableName } from "../enums/TableName";

export const clearMeta = async () => {
  await clear(TableName.Entities);
};

export const incrementTurn = async () => {
  const oldMeta = await readMeta();
  if (!oldMeta) {
    throw new Error("Meta not initialized");
  }
  const { turn } = oldMeta;
  return await mergeMeta({ turn: turn + 1 });
};

const mergeMeta = async (meta: Partial<Omit<Meta, "timestamp">>) => {
  const oldMeta = await readMeta();
  const newMeta = { ...oldMeta, ...meta, timestamp: +new Date() } as Meta;
  await write(TableName.Meta, META_KEY, newMeta);
  return newMeta;
};

export const readMeta = async () => {
  return await read<Meta>(TableName.Meta, META_KEY);
};

export const writeMeta = async (meta: Omit<Meta, "timestamp" | "turn">) => {
  const initialMeta = { turn: 1, ...meta };
  return await mergeMeta(initialMeta);
};
