import React from "react";
import { FormattedMessage } from "react-intl";
import Page from "../../../core/components/Page";
import ProgressBar from "../../../core/components/ProgressBar";
import { TASK_DEFINITIONS } from "../../../tasks/constants";
import { useActiveTasks } from "../../../tasks/hooks/useActiveTasks";
import "./styles.css";

const ActiveTasksPage: React.FunctionComponent = () => {
  const [activeTasks] = useActiveTasks();
  return (
    <Page id="page-active-tasks">
      {Object.keys(activeTasks).map(taskName => (
        <div className="active-task" key={taskName}>
          <FormattedMessage {...TASK_DEFINITIONS[taskName].title} />
          <ProgressBar progress={activeTasks[taskName].progress} />
        </div>
      ))}
    </Page>
  );
};

export default ActiveTasksPage;
