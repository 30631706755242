import { defineMessages } from "react-intl";

export const messages = defineMessages({
  backButtonLabel: {
    defaultMessage: "To the Game",
    description: "The label of the back-button",
    id: "Home.backButtonLabel"
  },
  content: {
    defaultMessage:
      "This is {appTitle}, a {roguelikeWikiLink} game taking place in ancient times — currently in development.",
    description: "The main content of the page",
    id: "Home.content"
  }
});
