import React from "react";
import { ColorName } from "../../../core/enums/ColorName";
import { Color } from "../../../core/types";
import { addOpacity } from "../../../core/utils/addOpacity";
import { useThemeColors } from "../../../settings/hooks/selectorHooks";
import "./styles.css";

interface Props {
  children: React.ReactNode;
}

const createGradient = (color: Color) => {
  const withAlpha = addOpacity(color, 0.8).asString;
  return `linear-gradient(${withAlpha}, ${withAlpha})`;
};

export const ScreenOverlay: React.FunctionComponent<Props> = ({ children }) => {
  const colors = useThemeColors();
  return (
    <div id="screen-overlay" style={{ background: createGradient(colors[ColorName.Background]) }}>
      {children}
    </div>
  );
};
