import { createKeyCommands } from "./factories/keyCommands";

export const CONTROL_TRIGGER_DELAY = 50;

export const GESTURE_ANGLE_PADDING = 5;

export const GESTURE_ANGLE_TOLERANCE = 2;

export const KEY_COMMANDS = createKeyCommands();

export const MAP_ZOOM_INCREMENT = 1;

export const MAP_ZOOM_MAX = 24;

export const MAP_ZOOM_MIN = -14;

export const MODULE_NAME = "userInput";

export const MOUSE_CLICK_MOVEMENT_THRESHOLD = 1;

export const MOUSE_DOUBLE_CLICK_MAX_DURATION = 300;

export const MOUSE_PAN_MOVEMENT_THRESHOLD = 1;

export const SECONDARY_CONTROLS_TOGGLE_RESIZE_TIMEOUT = 100;

export const TOUCH_BORDER_PAN_TOLERANCE = 20;

export const TOUCH_DOUBLE_TAP_MAX_DURATION = 200;

export const TOUCH_FLICK_MAX_DURATION = 200;

export const TOUCH_PAN_MOVEMENT_THRESHOLD = 3;

export const TOUCH_PINCH_MOVEMENT_THRESHOLD = 0.7;

export const TOUCH_SHORT_TAP_MAX_DURATION = 300;

export const TOUCH_TAP_MOVEMENT_THRESHOLD = 10;

export const VIBRATION_INTENSITY_FULL = 100;

export const VIBRATION_INTENSITY_LOW = 30;

export const VIBRATION_INTENSITY_MEDIUM = 60;

export const VIBRATION_INTENSITY_OFF = 0;
