import get from "lodash/get";
import React, { useLayoutEffect, useRef, useState } from "react";
import { ColorName } from "../../../core/enums/ColorName";
import { createSize } from "../../../core/factories/size";
import { createTileMap } from "../../../core/factories/tileMap";
import { TileMap } from "../../../core/types";
import {
  useCellTypeCache,
  useCoordinatesCache,
  useEntityCache,
  useIsSynching,
  useMapId,
  useMapSize,
  usePlayer
} from "../../../data/hooks/selectorHooks";
import { useIsResizing } from "../../../measuring/hooks/selectorHooks";
import { useComponentSize } from "../../../measuring/hooks/useComponentSize";
import { Coordinates, EntityId } from "../../../model/types";
import { useThemeColors, useTileSet } from "../../../settings/hooks/selectorHooks";
import { useMapZoom } from "../../../settings/hooks/stateHooks";
import { calculateBoundaries } from "./calculateBoundaries";
import "./styles.css";
import { createCellTile, createEntityTile } from "./tileFactory";
import { useKeyboardCommands } from "./useKeyboardCommands";
import { useMouseCommands } from "./useMouseCommands";
import { useTouchCommands } from "./useTouchCommands";

const WorldTileMap: React.FunctionComponent = () => {
  const innerRef = useRef<null | HTMLDivElement>(null);
  const outerRef = useRef<null | HTMLDivElement>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [mapZoom] = useMapZoom();
  const [pixelSize, setPixelSize] = useState(createSize(0, 0));
  const cellTypeCache = useCellTypeCache();
  const colors = useThemeColors();
  const coordinatesCache = useCoordinatesCache();
  const entityCache = useEntityCache();
  const focus: void | Coordinates = undefined; // TODO: implement
  const isResizing = useIsResizing();
  const isSyncingCache = useIsSynching();
  const mapId = useMapId();
  const mapSize = useMapSize();
  const player = usePlayer();
  const tileMap = useRef<null | TileMap>(null);
  const tileSet = useTileSet();
  useComponentSize(innerRef, { onResize: setPixelSize });
  useKeyboardCommands();
  useMouseCommands(innerRef);
  useTouchCommands(innerRef, outerRef);
  useLayoutEffect(() => {
    if (tileMap.current === null) {
      tileMap.current = createTileMap({
        backgroundColor: colors[ColorName.Background],
        onInitialize: () => setIsInitialized(true),
        onUnInitialize: () => setIsInitialized(false),
        pixelSize,
        tileSet
      });
    } else {
      if (innerRef.current !== null && isInitialized) {
        if (isResizing) {
          if (innerRef.current.contains(tileMap.current.view)) {
            innerRef.current.removeChild(tileMap.current.view);
          }
          return;
        }
        if (!innerRef.current.contains(tileMap.current.view)) {
          innerRef.current.appendChild(tileMap.current.view);
        }
        // update tile map
        const wasBgUpdated = tileMap.current.changeBackgroundColor(colors[ColorName.Background]);
        const wasSizeUpdated = tileMap.current.changeSize(pixelSize);
        const wasTileSetUpdated = tileMap.current.changeTileSet(tileSet);
        const wasTileZoomUpdated = tileMap.current.changeTileZoom(mapZoom);
        const needsRefreshing =
          wasBgUpdated || wasSizeUpdated || wasTileSetUpdated || wasTileZoomUpdated;
        if (!isSyncingCache && (isInitialized || needsRefreshing)) {
          // initialize tiles
          tileMap.current.clearTiles();
          const zoomedTileWidth = tileSet.tileSize.width + mapZoom;
          const zoomedTileHeight = tileSet.tileSize.height + mapZoom;
          const { boundingBox, offset } = calculateBoundaries(
            focus,
            mapSize,
            player,
            mapZoom,
            pixelSize,
            tileSet
          );
          const { xMin, xMax, yMin, yMax } = boundingBox;
          for (let x = xMin; x <= xMax; x++) {
            for (let y = yMin; y <= yMax; y++) {
              const cellType = get(cellTypeCache, [x, y], undefined);
              if (cellType !== undefined) {
                tileMap.current.addTile(createCellTile(cellType, x, y, colors));
                const entityIds: EntityId[] = get(coordinatesCache, [mapId, x, y], []);
                for (let i = 0, length = entityIds.length; i < length; i++) {
                  const entity = entityCache[entityIds[i]];
                  const entityTile = createEntityTile(entity, colors);
                  if (entityTile) {
                    tileMap.current.addTile(entityTile);
                  }
                }
              }
            }
            tileMap.current.changeSize(
              createSize((xMax - xMin + 1) * zoomedTileWidth, (yMax - yMin + 1) * zoomedTileHeight)
            );
            tileMap.current.changeTileOffset(offset);
            tileMap.current.render();
          }
        }
      }
    }
  });
  return (
    <div id="world-tile-map" ref={outerRef}>
      <div className="tile-map-container" ref={innerRef} />
    </div>
  );
};

export default WorldTileMap;
