import { messages } from "../definitions/messages";
import { TaskName } from "../enums/TaskName";
import { TaskDefinitions } from "../types";

export const createTaskDefinitions = (): TaskDefinitions => {
  const definitions: TaskDefinitions = {};
  for (const name of Object.values(TaskName)) {
    definitions[name] = {
      title: messages[name]
    };
  }
  return definitions;
};
