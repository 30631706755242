import tileSetDefinitions from "../definitions/tileSets.json";
import { FontName } from "../enums/FontName";
import { TileSetName } from "../enums/TileSetName";
import { TileSetDefinitions, TileSetJson } from "../types";
import { createTileSet } from "./tileSet";

export const createTileSets = () => {
  const result: TileSetDefinitions = {};
  for (const tileSetName of Object.values(TileSetName)) {
    const definition = (tileSetDefinitions as TileSetJson)[tileSetName];
    if (!definition) {
      throw new Error(
        `No definition for tile set '${tileSetName}' found in 'core/definitions/tileSets.json'`
      );
    }
    const { spriteSheetName, matchingFont, tileSize, useTint } = definition;
    if (!spriteSheetName || !matchingFont || typeof useTint !== "boolean") {
      throw new Error(
        `Incomplete definition for tile set '${tileSetName}' found in 'core/definitions/tileSets.json'`
      );
    }
    result[tileSetName] = createTileSet(
      spriteSheetName,
      matchingFont as FontName,
      tileSize,
      useTint
    );
  }
  return result;
};
