import { eventChannel } from "redux-saga";
import { call, cancel, delay, fork, put, take } from "redux-saga/effects";
import { createSize } from "../core/factories/size";
import { Size } from "../core/types";
import { setClientSize, setIsResizing } from "./actions";
import { RESIZE_TIMEOUT } from "./constants";

let task: any;

const createResizeChannel = () =>
  eventChannel(emitter => {
    const handler = (data: any) => {
      const { clientHeight, clientWidth } = data.target.document.documentElement;
      return emitter(createSize(clientWidth, clientHeight));
    };
    window.addEventListener("resize", handler, false);
    return () => window.removeEventListener("resize", handler, false);
  });

function* delayedReset() {
  yield delay(RESIZE_TIMEOUT);
  yield put(setIsResizing({ isResizing: false }));
  task = undefined;
}

function* handleIsResizing() {
  if (task) {
    yield cancel(task);
    task = undefined;
  } else {
    yield put(setIsResizing({ isResizing: true }));
  }
  task = yield fork(delayedReset);
}

export function* saga() {
  const channel = yield call(createResizeChannel);
  while (true) {
    const clientSize = (yield take(channel)) as Size;
    yield fork(handleIsResizing);
    yield put(setClientSize({ clientSize }));
  }
}
