import actionCreatorFactory from "typescript-fsa";
import { MODULE_NAME } from "./constants";
import { TaskName } from "./enums/TaskName";

const action = actionCreatorFactory(MODULE_NAME);

export const doTask = action.async<{ taskName: TaskName }, {}>("DO_TASK");

export const setProgress = action<{
  name: TaskName;
  progress: number;
}>("SET_PROGRESS");
