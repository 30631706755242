import { SpriteName } from "../enums/SpriteName";

const createLookupMap = () => {
  const map: { [key: string]: string } = {};
  for (const name of Object.values(SpriteName)) {
    map[name] = name;
  }
  return map;
};

const lookupMap = createLookupMap();

export const isSpriteName = (item: any | SpriteName): item is SpriteName =>
  lookupMap[item] !== undefined;
