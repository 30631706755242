import { useCallback } from "react";
import { useDispatch } from "redux-react-hook";
import { requestMovement } from "../actions";
import { Direction } from "../enums/Direction";

export const usePlayerAction = () => {
  const dispatch = useDispatch();
  const move = useCallback((direction: Direction) => dispatch(requestMovement({ direction })), []);
  return {
    move
  };
};
