import React, { useCallback } from "react";
import Button from "../../../core/components/Button";
import ItemBar from "../../../core/components/ItemBar";
import { HISTORY } from "../../../core/constants";
import { SpriteName } from "../../../core/enums/SpriteName";
import { CommandName } from "../../../userInput/enums/CommandName";
import { useMapControls } from "../../../userInput/hooks/useMapControls";
import { useSecondaryControls } from "../../../userInput/hooks/useSecondaryControls";
import { Url } from "../../enums/Url";
import "./styles.css";

const SecondaryControls: React.FunctionComponent = () => {
  const [isEnabled] = useSecondaryControls();
  const { decreaseZoom, increaseZoom, resetZoom } = useMapControls();
  const returnToMenu = useCallback(() => HISTORY.push(Url.Menu), []);
  return (
    <div className={isEnabled ? "" : "hidden"} id="secondary-controls">
      <ItemBar vertical={true}>
        <Button id="menu-button" onUse={returnToMenu}>
          {SpriteName.IconMenu}
        </Button>
        <Button id={CommandName.MapZoomIncrease} onUse={increaseZoom}>
          {SpriteName.IconPlus}
        </Button>
        <Button id={CommandName.MapZoomReset} onUse={resetZoom}>
          {SpriteName.IconZero}
        </Button>
        <Button id={CommandName.MapZoomDecrease} onUse={decreaseZoom}>
          {SpriteName.IconMinus}
        </Button>
      </ItemBar>
      <ItemBar vertical={true}>
        <></>
      </ItemBar>
    </div>
  );
};

export default SecondaryControls;
