import React from "react";
import { Route, RouteProps } from "react-router";
import ActiveTasksPage from "../../../app/components/ActiveTasksPage";
import { useActiveTasks } from "../../hooks/useActiveTasks";

const RouteOrActiveTasks: React.FunctionComponent<RouteProps> = ({ component, ...otherProps }) => {
  const hasActiveTasks = useActiveTasks()[1];
  return <Route component={hasActiveTasks ? ActiveTasksPage : component} {...otherProps} />;
};

export default RouteOrActiveTasks;
