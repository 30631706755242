import { call, fork, put, take } from "redux-saga/effects";
import { isType } from "typescript-fsa";
import { readSettings, writeSetting } from "../persistence/accessors/settings";
import * as actions from "./actions";
import { Setting, Settings } from "./types";

function* init() {
  const settings: Settings = yield call(readSettings);
  yield put(actions.initialize({ settings }));
}

function* write(setting: Setting) {
  yield call(writeSetting, setting);
}

export function* saga() {
  yield call(init);
  while (true) {
    const action = yield take(actions.update);
    if (isType(action, actions.update)) {
      yield fork(write, action.payload.setting);
    }
  }
}
