import { createSelector } from "reselect";
import { State } from "../core/types";
import { MODULE_NAME } from "./constants";

export const getCellTypeCache = (state: State) => state[MODULE_NAME].cellTypeCache;

export const getChunkSize = (state: State) => state[MODULE_NAME].chunkSize;

export const getEntityCache = (state: State) => state[MODULE_NAME].entityCache;

export const getIsSynched = (state: State) => state[MODULE_NAME].isSynched;

export const getIsSynching = (state: State) => state[MODULE_NAME].isSynching;

export const getCoordinatesCache = (state: State) => state[MODULE_NAME].coordinatesCache;

export const getMapMeta = (state: State) => state[MODULE_NAME].mapMeta;

export const getPlayerId = (state: State) => state[MODULE_NAME].playerId;

export const getSeed = (state: State) => state[MODULE_NAME].seed;

export const getTimestamp = (state: State) => state[MODULE_NAME].timestamp;

export const getTurn = (state: State) => state[MODULE_NAME].turn;

export const getHasSavegame = createSelector(
  getTimestamp,
  timestamp => timestamp !== 0
);

export const getPlayer = createSelector(
  [getEntityCache, getPlayerId],
  (entityCache, playerId) => entityCache[playerId]
);

export const getMapId = createSelector(
  getMapMeta,
  mapMeta => mapMeta.id
);

export const getMapSize = createSelector(
  getMapMeta,
  mapMeta => mapMeta.size
);
