import keyMapsDefinitions from "../definitions/keyMaps.json";
import { CommandName } from "../enums/CommandName";
import { KeyMapName } from "../enums/KeyMapName";
import { KeyCommandDefinitions, KeyCommandsJson } from "../types";
import { createKeyCommand } from "./keyCommand";

export const createKeyCommands = (): KeyCommandDefinitions => {
  const result: KeyCommandDefinitions = {};
  for (const keyMapName of Object.values(KeyMapName)) {
    result[keyMapName] = {};
    const keyMapCommands = (keyMapsDefinitions as KeyCommandsJson)[keyMapName];
    if (!keyMapCommands) {
      throw new Error(
        `Key map '${keyMapName}' is not defined in 'userInput/definitions/keyMaps.json'`
      );
    }
    for (const commandName of Object.values(CommandName)) {
      const command = keyMapCommands[commandName];
      if (!command) {
        throw new Error(
          `Command '${commandName}' of key map '${keyMapName}' is not defined in 'userInput/definitions/keyMaps.json'`
        );
      }
      const { key, modifiers = [] } = command;
      let alt = false;
      let ctrl = false;
      let shift = false;
      for (let i = 0, length = modifiers.length; i < length; i++) {
        switch (modifiers[i]) {
          case "alt":
            alt = true;
            break;
          case "ctrl":
            ctrl = true;
            break;
          case "shift":
            shift = true;
            break;
          default:
            throw new Error(
              `Illegal modifier value '${
                modifiers[i]
              }' in command '${commandName}' and key map '${keyMapName}' in 'userInput/definitions/keyMaps.json'`
            );
        }
      }
      result[keyMapName][commandName] = createKeyCommand(key, {
        alt,
        ctrl,
        shift
      });
    }
  }
  return result;
};
