import React, { MouseEvent, ReactText, useCallback } from "react";
import { stopEvent } from "../../utils/stopEvent";

interface Props {
  color: string;
  label: ReactText;
  onClick: VoidFunction;
  onMouseEnter: VoidFunction;
}

const Option: React.FunctionComponent<Props> = ({ color, label, onClick, onMouseEnter }) => {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      onClick();
      stopEvent(e);
    },
    [onClick]
  );
  return (
    <div
      className="select-option"
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      style={{
        color
      }}
    >
      {label}
    </div>
  );
};

export default Option;
