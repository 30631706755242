import get from "lodash/get";
import setWith from "lodash/setWith";
import { call, put, select } from "redux-saga/effects";
import { CellTypeMap, CoordinatesMap, Entity, EntityId, EntityMap } from "../../model/types";
import { getCoordinates } from "../../model/utils/getComponent";
import { readChunk } from "../../persistence/accessors/chunks";
import { readEntity, readEntityIds } from "../../persistence/accessors/entities";
import { readMapMeta } from "../../persistence/accessors/mapMeta";
import * as actions from "../actions";
import { CHUNK_RADIUS } from "../constants";
import { createChunkKey } from "../factories/chunkKey";
import { getChunkSize, getEntityCache, getPlayerId } from "../selectors";
import { currentChunkOrigins } from "../utils/currentChunkOrigins";
import { extractChunk } from "../utils/extractChunk";

export function* syncCache() {
  const chunkSize: number = yield select(getChunkSize);
  const playerId: EntityId = yield select(getPlayerId);
  const staleEntityCache: EntityMap = yield select(getEntityCache);
  const player: void | Entity =
    (yield select(getEntityCache))[playerId] || (yield call(readEntity, playerId));
  if (player) {
    const cellTypeCache: CellTypeMap = {};
    const playerCoordinates = getCoordinates(player)!;
    const mapMeta = yield call(readMapMeta, playerCoordinates.mapId);
    for (const chunkOrigin of currentChunkOrigins(playerCoordinates, chunkSize, CHUNK_RADIUS)) {
      const chunkKey = createChunkKey(chunkOrigin);
      const chunk = yield call(readChunk, chunkKey);
      if (chunk) {
        const extractedChunk = extractChunk(chunk);
        for (let relX = 0, columnSize = extractedChunk.length; relX < columnSize; relX++) {
          const column = extractedChunk[relX];
          for (let relY = 0, cellCount = column.length; relY < cellCount; relY++) {
            const absX = chunkOrigin.x * chunkSize + relX;
            const absY = chunkOrigin.y * chunkSize + relY;
            setWith(cellTypeCache, [absX, absY], column[relY], Object);
          }
        }
      }
    }
    const entityCache: EntityMap = {};
    const coordinatesCache: CoordinatesMap = {};
    const entityIds: EntityId[] = yield call(readEntityIds);
    for (let i = 0, length = entityIds.length; i < length; i++) {
      const id = entityIds[i];
      const entity = staleEntityCache[id] || (yield call(readEntity, id));
      const coordinates = getCoordinates(entity);
      if (coordinates) {
        const { mapId, x, y } = coordinates;
        entityCache[id] = entity;
        const atCoordinates = get(coordinatesCache, [mapId, x, y], []);
        setWith(coordinatesCache, [mapId, x, y], [...atCoordinates, id], Object);
      }
    }
    yield put(
      actions.syncCache.done({
        result: { cellTypeCache, entityCache, coordinatesCache, mapMeta }
      })
    );
  }
}
