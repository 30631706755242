import { createPoint } from "../../../core/factories/point";
import { createTile } from "../../../core/factories/tile";
import { Colors } from "../../../core/types";
import { CELL_TEMPLATES, ENTITY_TEMPLATES } from "../../../model/constants";
import { CellType } from "../../../model/enums/CellType";
import { Appearance, Entity } from "../../../model/types";
import { getAppearance, getCoordinates } from "../../../model/utils/getComponent";

export const createCellTile = (cellType: CellType, x: number, y: number, colors: Colors) => {
  const { color, sprite } = CELL_TEMPLATES[cellType];
  return createTile({
    mapPosition: createPoint(x, y),
    name: `${x},${y}`,
    spriteName: sprite,
    tint: colors[color]
  });
};

export const createEntityTile = (entity: Entity, colors: Colors) => {
  const { id } = entity;
  const appearance =
    getAppearance(entity) || (ENTITY_TEMPLATES[entity.type].appearance as Appearance);
  const coordinates = getCoordinates(entity)!;
  return createTile({
    mapPosition: coordinates,
    name: String(id),
    spriteName: appearance.sprite,
    tint: colors[appearance.color]
  });
};
