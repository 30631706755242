import { KeyName } from "../enums/KeyName";
import { KeyCommand, KeyModifiers } from "../types";

export const createKeyCommand = (
  key: string | KeyName,
  { alt, ctrl, shift }: KeyModifiers
): KeyCommand => ({
  alt: alt || false,
  ctrl: ctrl || false,
  key,
  shift: shift || false
});
