import React, { useCallback, useEffect } from "react";
import { useIsMobile } from "../../../measuring/hooks/selectorHooks";
import { useColorStrings } from "../../../settings/hooks/colorHooks";
import { useActiveControl } from "../../../userInput/hooks/useActiveControl";
import { useKeyboard } from "../../../userInput/hooks/useKeyboard";
import { useTrigger } from "../../../userInput/hooks/useTrigger";
import { ColorName } from "../../enums/ColorName";
import { SpriteName } from "../../enums/SpriteName";
import { isSpriteName } from "../../utils/isSpriteName";
import { joinStrings } from "../../utils/joinStrings";
import SpriteIcon from "../SpriteIcon";
import "./styles.css";

type Children = string | SpriteName | JSX.Element;

export interface Props {
  children: Children;
  className?: string;
  autoFocus?: boolean;
  id: string;
  onUse?: VoidFunction;
}

const isSquare = (children: Children) =>
  isSpriteName(children) || (typeof children === "string" && children.length === 1);

const Button: React.FunctionComponent<Props> = ({ autoFocus, children, className, id, onUse }) => {
  const [activeColor, passiveColor, triggeredColor] = useColorStrings(
    ColorName.Primary,
    ColorName.Text,
    ColorName.Secondary
  );
  const isMobile = useIsMobile();
  const [activeId, setActive] = useActiveControl();
  const isActive = id === activeId;
  const activate = useCallback(() => setActive(id), [id]);
  const deactivate = useCallback(() => setActive(), []);
  const [isTriggered, trigger] = useTrigger();
  const handleKeyboard = useCallback(() => {
    if (isActive) {
      trigger(id, onUse);
    }
  }, [isActive, id, onUse, trigger]);
  const handleMouseUp = useCallback(() => {
    trigger(id, onUse);
  }, [id, onUse, trigger]);
  useKeyboard({ onEnter: handleKeyboard });
  useEffect(() => {
    if (autoFocus) {
      activate();
    }
  }, [isMobile]);
  return (
    <div
      className={joinStrings(["button", className, isSquare(children) ? "square" : ""])}
      id={id}
      onMouseEnter={activate}
      onMouseLeave={deactivate}
      onMouseUp={handleMouseUp}
      style={{
        borderColor: isTriggered(id)
          ? triggeredColor
          : isMobile || !isActive
          ? passiveColor
          : activeColor
      }}
    >
      {isSpriteName(children) ? <SpriteIcon sprite={children} /> : <div>{children}</div>}
    </div>
  );
};

export default Button;
