export const calculateAngle = (x1: number, y1: number, x2: number, y2: number): number => {
  const dy = y2 - y1;
  const dx = x2 - x1;
  let theta = Math.atan2(dy, dx);
  theta *= 180 / Math.PI;
  if (theta < 0) {
    theta = 360 + theta;
  } // range [0, 360)
  return theta;
};
