import { useCallback, useEffect, useState } from "react";
import { ElementRef } from "../../core/types";
import {
  TOUCH_BORDER_PAN_TOLERANCE,
  TOUCH_DOUBLE_TAP_MAX_DURATION,
  TOUCH_FLICK_MAX_DURATION,
  TOUCH_SHORT_TAP_MAX_DURATION
} from "../constants";
import { Direction } from "../enums/Direction";
import { GestureName } from "../enums/GestureName";
import { VibrationIntensity } from "../enums/VibrationIntensity";
import { createGestureEvent } from "../factories/gestureEvent";
import { createTouchGesture } from "../factories/touchGesture";
import {
  Gesture,
  GestureEvent,
  PanCallback,
  PanData,
  PinchCallback,
  PinchData,
  TapCallback,
  TapData,
  TouchGestureEvent
} from "../types";
import { vibrate } from "../utils/vibrate";

interface Options {
  onBottomBorderPan?: void | PanCallback;
  onBottomBorderPanEnd?: void | PanCallback;
  onDoubleTap?: void | TapCallback;
  onFlick?: void | PanCallback;
  onLeftBorderPan?: void | PanCallback;
  onLeftBorderPanEnd?: void | PanCallback;
  onLongTap?: void | TapCallback;
  onPan?: void | PanCallback;
  onPanEnd?: void | PanCallback;
  onPinch?: void | PinchCallback;
  onPinchEnd?: void | PinchCallback;
  onRightBorderPan?: void | PanCallback;
  onRightBorderPanEnd?: void | PanCallback;
  onShortTap?: void | TapCallback;
  onTopBorderPan?: void | PanCallback;
  onTopBorderPanEnd?: void | PanCallback;
  vibrationIntensity?: VibrationIntensity;
}

interface TouchState {
  doubleTapTimeout: void | NodeJS.Timeout;
  events: TouchGestureEvent[];
  gesture: void | Gesture;
  lastEventEnd: number;
  target: ElementRef;
}

const activateCallbacks = (state: TouchState, options: Options, isEnd: boolean) => {
  if (state.gesture) {
    const { data, name } = state.gesture;
    switch (name) {
      case GestureName.Pan:
        return onPan(data, state, options, isEnd);
      case GestureName.Pinch:
        return onPinch(data, options, isEnd);
      case GestureName.Tap:
        return onTap(data, state, options, isEnd);
    }
  }
};

const createTouchGestureEvent = (e: Touch, previous?: GestureEvent): TouchGestureEvent => ({
  ...createGestureEvent(e, previous),
  id: e.identifier
});

const findCorrectGesture = (
  events: TouchGestureEvent[],
  previousEvents: TouchGestureEvent[],
  previousGesture: void | Gesture
): void | Gesture => {
  const gesture = createTouchGesture(events, previousEvents);
  if (gesture === undefined) {
    return previousGesture;
  }
  if (previousGesture === undefined) {
    return gesture;
  }
  const { name: currName } = gesture;
  const { name: prevName } = previousGesture;
  return (currName === GestureName.Tap &&
    (prevName === GestureName.Pan || prevName === GestureName.Pinch)) ||
    (currName === GestureName.Pan && prevName === GestureName.Pinch)
    ? previousGesture
    : gesture;
};

const isHorizontal = (direction: Direction) =>
  direction === Direction.Left || direction === Direction.Right;

const isVertical = (direction: Direction) =>
  direction === Direction.Up || direction === Direction.Down;

const mapTouchList = (list: TouchList, events: TouchGestureEvent[]): TouchGestureEvent[] =>
  Array.from(list).map(t => createTouchGestureEvent(t, events.find(e => e.id === t.identifier)));

const onPan = (data: PanData, state: TouchState, options: Options, isEnd: boolean) => {
  const { target } = state;
  if (!target.current) {
    return;
  }
  const { origin, direction, duration } = data;
  const { height, width } = target.current.getBoundingClientRect();
  if (isEnd && options.onFlick && duration < TOUCH_FLICK_MAX_DURATION) {
    vibrate(options.vibrationIntensity);
    options.onFlick(data);
    return;
  }
  if (origin.x < TOUCH_BORDER_PAN_TOLERANCE) {
    if (!isEnd && isHorizontal(direction) && options.onLeftBorderPan) {
      options.onLeftBorderPan(data);
      return;
    }
    if (isEnd && isHorizontal(direction) && options.onLeftBorderPanEnd) {
      vibrate(options.vibrationIntensity);
      options.onLeftBorderPanEnd(data);
      return;
    }
  }
  if (origin.y < TOUCH_BORDER_PAN_TOLERANCE) {
    if (!isEnd && isVertical(direction) && options.onTopBorderPan) {
      options.onTopBorderPan(data);
      return;
    }
    if (isEnd && isVertical(direction) && options.onTopBorderPanEnd) {
      vibrate(options.vibrationIntensity);
      options.onTopBorderPanEnd(data);
      return;
    }
  }
  if (origin.x > width - TOUCH_BORDER_PAN_TOLERANCE) {
    if (!isEnd && isHorizontal(direction) && options.onRightBorderPan) {
      options.onRightBorderPan(data);
      return;
    }
    if (isEnd && isHorizontal(direction) && options.onRightBorderPanEnd) {
      vibrate(options.vibrationIntensity);
      options.onRightBorderPanEnd(data);
      return;
    }
  }
  if (origin.y > height - TOUCH_BORDER_PAN_TOLERANCE) {
    if (!isEnd && isVertical(direction) && options.onBottomBorderPan) {
      options.onBottomBorderPan(data);
      return;
    }
    if (isEnd && isVertical(direction) && options.onBottomBorderPanEnd) {
      vibrate(options.vibrationIntensity);
      options.onBottomBorderPanEnd(data);
      return;
    }
  }
  if (duration > TOUCH_FLICK_MAX_DURATION) {
    if (!isEnd && options.onPan) {
      options.onPan(data);
    }
    if (isEnd && options.onPanEnd) {
      vibrate(options.vibrationIntensity);
      options.onPanEnd(data);
      return;
    }
  }
};

const onPinch = (data: PinchData, options: Options, isEnd: boolean) => {
  if (isEnd && options.onPinchEnd) {
    vibrate(options.vibrationIntensity);
    options.onPinchEnd(data);
    return;
  }
  if (!isEnd && options.onPinch) {
    options.onPinch(data);
    return;
  }
};

const onTap = (data: TapData, state: TouchState, options: Options, isEnd: boolean) => {
  const { doubleTapTimeout, lastEventEnd } = state;
  if (isEnd) {
    const now = new Date().getTime();
    const timeSinceLastTap = now - lastEventEnd;
    const duration = now - data.startTime;
    if (duration > TOUCH_SHORT_TAP_MAX_DURATION) {
      if (options.onLongTap) {
        vibrate(options.vibrationIntensity);
        options.onLongTap(data);
      }
    } else if (timeSinceLastTap < TOUCH_DOUBLE_TAP_MAX_DURATION && doubleTapTimeout !== undefined) {
      clearTimeout(doubleTapTimeout);
      if (options.onDoubleTap) {
        vibrate(options.vibrationIntensity);
        options.onDoubleTap(data);
      }
    } else {
      return setTimeout(() => {
        if (options.onShortTap) {
          vibrate(options.vibrationIntensity);
          options.onShortTap(data);
        }
      }, TOUCH_DOUBLE_TAP_MAX_DURATION + 1);
    }
  }
};

export const useTouch = (ref: ElementRef, options: Options) => {
  const [state, setState] = useState<TouchState>({
    doubleTapTimeout: undefined,
    events: [],
    gesture: undefined,
    lastEventEnd: new Date().getTime(),
    target: ref
  });
  const handleStart = useCallback(
    (e: TouchEvent) => {
      const previousEvents = state.events;
      state.events = Array.from(e.touches).map(touch => createTouchGestureEvent(touch));
      state.gesture = createTouchGesture(state.events, previousEvents);
      setState(state);
    },
    [options]
  );
  const handleMove = useCallback(
    (e: TouchEvent) => {
      if (state.events.length > 0) {
        const previousEvents = state.events;
        const previousGesture = state.gesture;
        state.events = mapTouchList(e.touches, previousEvents);
        state.gesture = findCorrectGesture(state.events, previousEvents, previousGesture);
        state.doubleTapTimeout = activateCallbacks(state, options, false);
        setState(state);
      }
    },
    [options]
  );
  const handleEnd = useCallback(
    (e: TouchEvent) => {
      state.events = mapTouchList(e.touches, state.events);
      const isFinal = state.events.length === 0;
      if (isFinal) {
        state.doubleTapTimeout = activateCallbacks(state, options, true);
        state.gesture = undefined;
      }
      state.lastEventEnd = new Date().getTime();
      setState(state);
    },
    [options]
  );
  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("touchend", handleEnd, false);
      ref.current.addEventListener("touchmove", handleMove, false);
      ref.current.addEventListener("touchstart", handleStart, false);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener("touchend", handleEnd, false);
        ref.current.removeEventListener("touchmove", handleMove, false);
        ref.current.removeEventListener("touchstart", handleStart, false);
      }
    };
  }, [options]);
};
