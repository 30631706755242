import { call, fork, put, take } from "redux-saga/effects";
import { isType } from "typescript-fsa";
import { getWorld } from "../../api/actions";
import { WorldParameters } from "../../api/types";
import { Url } from "../../app/enums/Url";
import { HISTORY } from "../../core/constants";
import * as notificationsActions from "../../notifications/actions";
import { NotificationName } from "../../notifications/enums/NotificationName";
import { createNotification } from "../../notifications/factories/notification";
import { clearChunks, writeChunks } from "../../persistence/accessors/chunks";
import { clearEntities, writeEntities } from "../../persistence/accessors/entities";
import { clearMapMeta, writeMapMeta } from "../../persistence/accessors/mapMeta";
import { clearMeta, writeMeta } from "../../persistence/accessors/meta";
import * as taskActions from "../../tasks/actions";
import { TaskName } from "../../tasks/enums/TaskName";
import * as dataActions from "../actions";

function* handleFailedCreation(parameters: WorldParameters) {
  yield put(taskActions.setProgress({ name: TaskName.CreateSavegame, progress: 100 }));
  yield put(taskActions.doTask.done({ params: { taskName: TaskName.CreateSavegame }, result: {} }));
  const notification = createNotification(NotificationName.CannotReachApi);
  yield put(notificationsActions.addNotification({ notification }));
  while (true) {
    const action = yield take(notificationsActions.removeNotification);
    if (
      isType(action, notificationsActions.removeNotification) &&
      action.payload.id === notification.id
    ) {
      yield put(dataActions.createSavegame.failed({ params: parameters, error: {} }));
      return;
    }
  }
}

export function* clearStorage() {
  yield put(dataActions.resetCache());
  yield call(clearChunks);
  yield call(clearEntities);
  yield call(clearMapMeta);
  yield call(clearMeta);
}

export function* createSaveGame(parameters: WorldParameters) {
  yield put(taskActions.doTask.started({ taskName: TaskName.CreateSavegame }));
  yield put(taskActions.setProgress({ name: TaskName.CreateSavegame, progress: 10 }));
  yield put(getWorld.started({ parameters }));
  const action = yield take([getWorld.done.type, getWorld.failed.type]);
  yield put(taskActions.setProgress({ name: TaskName.CreateSavegame, progress: 80 }));
  if (isType(action, getWorld.failed)) {
    yield fork(handleFailedCreation, parameters);
  } else if (isType(action, getWorld.done)) {
    yield call(clearStorage);
    const { chunks: cellTypeChunks, entities, mapMeta, ...meta } = action.payload.result.world;
    yield call(writeMapMeta, mapMeta);
    yield put(taskActions.setProgress({ name: TaskName.CreateSavegame, progress: 85 }));
    yield call(writeChunks, cellTypeChunks);
    yield put(taskActions.setProgress({ name: TaskName.CreateSavegame, progress: 90 }));
    const updatedMeta = yield call(writeMeta, meta);
    yield put(taskActions.setProgress({ name: TaskName.CreateSavegame, progress: 95 }));
    yield call(writeEntities, entities);
    yield put(
      dataActions.createSavegame.done({ params: parameters, result: { meta: updatedMeta } })
    );
    yield put(taskActions.setProgress({ name: TaskName.CreateSavegame, progress: 100 }));
    yield put(
      taskActions.doTask.done({ params: { taskName: TaskName.CreateSavegame }, result: {} })
    );
    HISTORY.push(Url.Game);
    yield put(dataActions.syncCache.started());
  }
}
