import {
  VIBRATION_INTENSITY_FULL,
  VIBRATION_INTENSITY_LOW,
  VIBRATION_INTENSITY_MEDIUM,
  VIBRATION_INTENSITY_OFF
} from "../constants";
import { VibrationIntensity } from "../enums/VibrationIntensity";

export const numberFromVibrationIntensity = (vibrationIntensity: VibrationIntensity): number => {
  switch (vibrationIntensity) {
    case VibrationIntensity.Full:
      return VIBRATION_INTENSITY_FULL;
    case VibrationIntensity.Medium:
      return VIBRATION_INTENSITY_MEDIUM;
    case VibrationIntensity.Low:
      return VIBRATION_INTENSITY_LOW;
    default:
      return VIBRATION_INTENSITY_OFF;
  }
};
