import { createSelector } from "reselect";
import { COLORS, FONTS, TILE_SETS } from "../core/constants";
import { State } from "../core/types";
import { KEY_COMMANDS } from "../userInput/constants";
import { numberFromVibrationIntensity } from "../userInput/utils/numberFromVibrationIntensity";
import { MODULE_NAME } from "./constants";

export const getColorThemeName = (state: State) => state[MODULE_NAME].colorThemeName;

export const getIsInitialized = (state: State) => state[MODULE_NAME].isInitialized;

export const getKeyMapName = (state: State) => state[MODULE_NAME].keyMapName;

export const getMapZoom = (state: State) => state[MODULE_NAME].mapZoom;

export const getTileSetName = (state: State) => state[MODULE_NAME].tileSetName;

export const getToggles = (state: State) => state[MODULE_NAME].toggles;

export const getVibrationIntensity = (state: State) => state[MODULE_NAME].vibrationIntensity;

export const getKeyCommands = createSelector(
  getKeyMapName,
  name => KEY_COMMANDS[name]
);

export const getVibrationIntensityValue = createSelector(
  getVibrationIntensity,
  numberFromVibrationIntensity
);

export const getThemeColors = createSelector(
  getColorThemeName,
  name => COLORS[name]
);

export const getTileSet = createSelector(
  getTileSetName,
  name => TILE_SETS[name]
);

export const getFont = createSelector(
  getTileSet,
  tileSet => FONTS[tileSet.matchingFont]
);
