import { defineMessages } from "react-intl";

export const messages = defineMessages({
  backButtonLabel: {
    defaultMessage: "Cancel",
    description: "The label of the back-button",
    id: "NewGameMenu.backButtonLabel"
  },
  createButtonLabel: {
    defaultMessage: "Create Game",
    description: "The label of the create-button",
    id: "NewGameMenu.createButtonLabel"
  },
  labelSeed: {
    defaultMessage: "World seed",
    description: "The label of the seed control",
    id: "NewGameMenu.labelSeed"
  }
});
