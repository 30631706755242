import React from "react";
import { useColorStrings } from "../../../settings/hooks/colorHooks";
import { ColorName } from "../../enums/ColorName";

interface Props {
  href: string;
  children: string | JSX.Element;
  newTab?: boolean;
}

const Link: React.FunctionComponent<Props> = ({ children, href, newTab }) => {
  const [color] = useColorStrings(ColorName.Primary);
  return (
    <a href={href} target={newTab ? "_blank" : undefined} style={{ color }}>
      {children}
    </a>
  );
};

Link.defaultProps = {
  newTab: false
};

export default Link;
