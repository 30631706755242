import { KeyCommand, KeyEvent } from "../types";

export const isKeyCommand = (keyCommand: KeyCommand, event: KeyEvent) => {
  if (!event.key || event.key.toLocaleLowerCase() !== keyCommand.key.toLocaleLowerCase()) {
    return false;
  }
  if (event.alt !== undefined && event.alt !== keyCommand.alt) {
    return false;
  }
  if (event.ctrl !== undefined && event.ctrl !== keyCommand.ctrl) {
    return false;
  }
  if (event.shift !== undefined && event.shift !== keyCommand.shift) {
    return false;
  }
  return true;
};
