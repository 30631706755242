import { useCallback, useEffect } from "react";
import { stopEvent } from "../../core/utils/stopEvent";
import { KeyCode } from "../enums/KeyCode";
import { KeyName } from "../enums/KeyName";
import { KeyEventCallback } from "../types";

interface Options {
  disable?: boolean;
  onArrowDown?: KeyEventCallback;
  onArrowKey?: KeyEventCallback;
  onArrowLeft?: KeyEventCallback;
  onArrowRight?: KeyEventCallback;
  onArrowUp?: KeyEventCallback;
  onEnter?: KeyEventCallback;
  onEscape?: KeyEventCallback;
  onKeyDown?: KeyEventCallback;
  onSpace?: KeyEventCallback;
  onTab?: KeyEventCallback;
  preventDefault?: boolean;
}

const handleCallback = (e: KeyboardEvent, preventDefault: void | boolean) => (
  callback: void | KeyEventCallback
) => {
  const { altKey, code, ctrlKey, key, shiftKey } = e;
  if (callback) {
    callback({
      alt: altKey,
      code: (code as any) as void | KeyCode, // can be undefined in some browsers
      ctrl: ctrlKey,
      key: key as KeyName,
      originalEvent: e,
      shift: shiftKey
    });
  }
  if (preventDefault) {
    stopEvent(e);
  }
};

const handleKeyDown = (options: Options) => (e: KeyboardEvent) => {
  if (!options.disable) {
    const doCallback = handleCallback(e, options.preventDefault);
    doCallback(options.onKeyDown);
    switch (e.key) {
      case KeyName.ArrowDown:
        doCallback(options.onArrowKey);
        doCallback(options.onArrowDown);
        break;
      case KeyName.ArrowLeft:
        doCallback(options.onArrowKey);
        doCallback(options.onArrowLeft);
        break;
      case KeyName.ArrowRight:
        doCallback(options.onArrowKey);
        doCallback(options.onArrowRight);
        break;
      case KeyName.ArrowUp:
        doCallback(options.onArrowKey);
        doCallback(options.onArrowUp);
        break;
      case KeyName.Enter:
        doCallback(options.onEnter);
        break;
      case KeyName.Escape:
        doCallback(options.onEscape);
        break;
      case KeyName.Tab:
        doCallback(options.onTab);
        break;
      case " ":
        doCallback(options.onSpace);
        break;
    }
  }
};

export const useKeyboard = (options: Options) => {
  const onKeyDown = useCallback(handleKeyDown(options), [options]);
  useEffect(() => {
    window.addEventListener("keydown", onKeyDown, false);
    return () => window.removeEventListener("keydown", onKeyDown, false);
  }, [options]);
};
