import { ColorName } from "../core/enums/ColorName";
import { ColorThemeName } from "../core/enums/ColorThemeName";
import { TileSetName } from "../core/enums/TileSetName";
import { KeyMapName } from "../userInput/enums/KeyMapName";
import { VibrationIntensity } from "../userInput/enums/VibrationIntensity";

export const DEFAULT_COLOR_THEME_NAME = ColorThemeName.Dawnbringer32;

export const DEFAULT_KEY_MAP_NAME = KeyMapName.Laptop;

export const DEFAULT_MAP_ZOOM = 0;

export const DEFAULT_TILE_SET_NAME = TileSetName.SquarePixel;

export const DEFAULT_VIBRATION_INTENSITY = VibrationIntensity.Medium;

export const FALLBACK_COLOR_NAME = ColorName.Purple;

export const MODULE_NAME = "settings";
