import { defineMessages } from "react-intl";

export const messages = defineMessages({
  backButtonLabel: {
    defaultMessage: "Back to Home",
    description: "The label of the back-button",
    id: "GameMenu.backButtonLabel"
  },
  continueButtonLabel: {
    defaultMessage: "Continue Game",
    description: "The label of the button that continues a saved game",
    id: "GameMenu.continueButtonLabel"
  },
  settingsButtonLabel: {
    defaultMessage: "Settings",
    description: "The label of the button that leads to the settings menu",
    id: "GameMenu.settingsButtonLabel"
  },
  startNewGameButtonLabel: {
    defaultMessage: "Start new Game",
    description: "The label of the button that starts a new game",
    id: "GameMenu.newGameButtonLabel"
  }
});
