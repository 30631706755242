import React, { Children, useCallback, useEffect, useRef, useState } from "react";
import { useActiveControl } from "../../../userInput/hooks/useActiveControl";
import { useKeyboard } from "../../../userInput/hooks/useKeyboard";
import { KeyEvent } from "../../../userInput/types";
import { stopEvent } from "../../utils/stopEvent";
import "./styles.css";

type Children = void | JSX.Element | JSX.Element[];

interface Props {
  children: Children;
  id?: string;
}

interface ActivationState {
  ids: string[];
  index: number;
  maxIndex: number;
}

/**
 * Walks through all children (if any) and creates a list of the first ids
 *  it encounters for all child-trees.
 */
const mapIds = (children: Children) => {
  const ids: string[] = [];
  const mapCurrent = (current: Children) => {
    if (current) {
      Children.toArray(current).forEach(child => {
        if (!React.isValidElement(child)) {
          return;
        }
        const props = child.props as any;
        if (props.id) {
          ids.push(props.id);
        } else {
          mapCurrent(props.children);
        }
      });
    }
  };
  mapCurrent(children);
  return ids;
};

const useChildNavigation = (children: Children) => {
  if (!children) {
    return {
      toNext: () => undefined,
      toPrevious: () => undefined
    };
  }
  const idCount = useRef(-1);
  const [state, setState] = useState<ActivationState>({
    ids: [],
    index: -1,
    maxIndex: -1
  });
  const [activeId, setActiveId] = useActiveControl();
  useEffect(() => {
    const childArray = Children.toArray(children);
    const ids = mapIds(children);
    const maxIndex = ids.length - 1;
    const index = ids.findIndex(id => id === activeId);
    if (activeId === undefined && maxIndex > 0) {
      setActiveId(state.ids[0]);
    }
    if (maxIndex !== state.maxIndex || index !== state.index) {
      idCount.current = childArray.length;
      setState({ ids, index, maxIndex });
    }
  });
  return {
    toNext: () => setActiveId(state.ids[state.index >= state.maxIndex ? 0 : state.index + 1]),
    toPrevious: () => setActiveId(state.ids[state.index <= 0 ? state.maxIndex : state.index - 1])
  };
};

const Menu: React.FunctionComponent<Props> = ({ children, id }) => {
  const { toNext, toPrevious } = useChildNavigation(children);
  const [, setActiveId, changeSettings] = useActiveControl();
  const handleTab = useCallback(
    (e: KeyEvent) => {
      if (e.shift) {
        toPrevious();
      } else {
        toNext();
      }
      stopEvent(e);
    },
    [toPrevious, toNext]
  );
  useKeyboard({
    onArrowDown: toNext,
    onArrowUp: toPrevious,
    onTab: handleTab
  });
  useEffect(() => {
    setActiveId();
    changeSettings({ isGuaranteed: true });
    return () => {
      changeSettings({ isGuaranteed: false });
      setActiveId();
    };
  }, []);
  return (
    <div className="menu" id={id}>
      {children}
    </div>
  );
};

export default Menu;
