import React from "react";
import { FormattedMessage } from "react-intl";
import Link from "../../../core/components/Link";
import Page from "../../../core/components/Page";
import { APP_TITLE } from "../../../core/constants";
import { Url } from "../../enums/Url";
import { messages } from "./messages";

const HomePage: React.FunctionComponent = () => (
  <Page
    headline={APP_TITLE}
    id="page-home"
    returnButtonText={<FormattedMessage {...messages.backButtonLabel} />}
    returnTo={Url.Menu}
  >
    <FormattedMessage
      {...messages.content}
      values={{
        appTitle: <em>{APP_TITLE}</em>,
        roguelikeWikiLink: (
          <Link href="https://en.wikipedia.org/wiki/Roguelike" newTab={true}>
            roguelike
          </Link>
        )
      }}
    />
  </Page>
);

export default HomePage;
