import React, {
  FocusEvent,
  FormEvent,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { useIsMobile } from "../../../measuring/hooks/selectorHooks";
import { useColorStrings } from "../../../settings/hooks/colorHooks";
import { KeyName } from "../../../userInput/enums/KeyName";
import { useActiveControl } from "../../../userInput/hooks/useActiveControl";
import { useKeyboard } from "../../../userInput/hooks/useKeyboard";
import { useTrigger } from "../../../userInput/hooks/useTrigger";
import { KeyEvent } from "../../../userInput/types";
import { ColorName } from "../../enums/ColorName";
import { joinStrings } from "../../utils/joinStrings";
import { stopEvent } from "../../utils/stopEvent";
import "./styles.css";

type InputType = "text";

interface Props {
  className?: string;
  defaultValue?: string;
  id: string;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
}

const selectElementContents = (el: HTMLElement) => {
  const range = document.createRange();
  range.selectNodeContents(el);
  const sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);
};

const setEndOfContenteditable = (el: HTMLElement) => {
  let range;
  let selection;
  if (document.createRange) {
    range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

const Input: React.FunctionComponent<Props> = ({
  className,
  defaultValue = "",
  id,
  onChange,
  onSubmit
}) => {
  const [activeColor, passiveColor, triggeredColor] = useColorStrings(
    ColorName.Primary,
    ColorName.Text,
    ColorName.Secondary
  );
  const isMobile = useIsMobile();
  const initialValue = useRef(defaultValue);
  const contentEditable = useRef<null | HTMLDivElement>(null);
  const [isTriggered, trigger] = useTrigger();
  const [isEditing, setIsEditing] = useState(false);
  const [activeId, setActive, changeSettings] = useActiveControl();
  const isActive = id === activeId;
  const activate = useCallback(() => setActive(id), [id]);
  const deactivate = useCallback(() => setActive(), []);
  const lock = useCallback(() => changeSettings({ isLocked: true }), []);
  const unlock = useCallback(() => changeSettings({ isLocked: false }), []);
  const startEditing = useCallback(
    (
      e: void | MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement> | KeyEvent,
      selectAll = true
    ) => {
      const element = contentEditable.current!;
      activate();
      lock();
      setIsEditing(true);
      if (selectAll) {
        selectElementContents(element);
      } else {
        setEndOfContenteditable(element);
      }
      stopEvent(e);
    },
    [contentEditable, activate, lock, setIsEditing]
  );
  const stopEditing = useCallback(
    (e: void | KeyboardEvent<HTMLDivElement> | FocusEvent<HTMLDivElement>) => {
      deactivate();
      unlock();
      setIsEditing(false);
      stopEvent(e);
    },
    [deactivate, unlock, setIsEditing]
  );
  const doChange = useCallback(
    (value: string) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );
  const doSubmit = useCallback(
    (e?: KeyboardEvent<HTMLDivElement> | FocusEvent<HTMLDivElement>) => {
      const value = contentEditable.current!.innerText;
      stopEditing(e);
      if (value.length > 0 && value !== " ") {
        if (onSubmit) {
          trigger(id, () => onSubmit(value));
        }
        initialValue.current = value;
        contentEditable.current!.innerText = value;
      } else {
        contentEditable.current!.innerText = initialValue.current;
      }
    },
    [contentEditable, id, initialValue, onSubmit, stopEditing, trigger]
  );
  // handles global keypresses
  const handleGlobalKeyDown = useCallback(
    (e: KeyEvent) => {
      const { key } = e;
      if (key === KeyName.Enter || key === KeyName.ArrowRight) {
        startEditing(e);
      }
      if (key && key.length === 1) {
        contentEditable.current!.innerText = key;
        startEditing(e, false);
        doChange(key);
      }
    },
    [contentEditable, startEditing]
  );
  // handles key down events when the content editable element is selected during editing
  const handleEditableKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === KeyName.Escape) {
        contentEditable.current!.innerText = initialValue.current;
        stopEditing(e);
      } else if (e.key === KeyName.Enter || e.key === KeyName.Tab) {
        doSubmit();
      }
    },
    [contentEditable, doSubmit, stopEditing]
  );
  // handles input events on the content editable element
  const handleEditableInput = useCallback(
    (e: FormEvent<HTMLDivElement>) => doChange(e.currentTarget.innerText),
    [onChange]
  );
  useKeyboard({
    onKeyDown: isActive && !isEditing ? handleGlobalKeyDown : undefined
  });
  useEffect(() => {
    if (contentEditable.current) {
      contentEditable.current.innerText = defaultValue;
      initialValue.current = defaultValue;
    }
  }, [contentEditable, defaultValue]);
  useEffect(() => {
    if (isActive && isEditing) {
      contentEditable.current!.focus();
    }
  }, [contentEditable, isActive, isEditing, isMobile]);
  return (
    <div
      className={joinStrings(["input", className])}
      id={id}
      onMouseEnter={activate}
      onMouseLeave={deactivate}
      onMouseUp={isEditing ? undefined : startEditing}
      style={{
        borderColor:
          isEditing || isTriggered(id)
            ? triggeredColor
            : isMobile || !isActive
            ? passiveColor
            : activeColor
      }}
    >
      <div
        className="input-content"
        contentEditable={isActive && isEditing}
        onBlur={doSubmit}
        onInput={handleEditableInput}
        onKeyDown={handleEditableKeyDown}
        ref={contentEditable}
      />
    </div>
  );
};

export default Input;
