import React from "react";
import { SpriteName } from "../../enums/SpriteName";
import { SelectOption } from "../../types";
import SpriteIcon from "../SpriteIcon";

interface Props {
  passiveColor: string;
  selected?: SelectOption;
}

const Selector: React.FunctionComponent<Props> = ({ passiveColor, selected }) => (
  <div className="select-selector">
    <div style={{ color: passiveColor }}>{selected ? selected.label : ""}</div>
    <SpriteIcon className="select-arrow" sprite={SpriteName.IconArrowDown} />
  </div>
);
export default Selector;
