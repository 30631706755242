import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import Page from "../../../core/components/Page";
import { APP_TITLE } from "../../../core/constants";
import { useHasSavegame } from "../../../data/hooks/selectorHooks";
import { Url } from "../../enums/Url";
import GameMenu from "../GameMenu";
import NewGameMenu from "../NewGameMenu";
import { messages } from "./messages";

const MenuPage: React.FunctionComponent = () => {
  const hasSaveGame = useHasSavegame();
  const [showNewGameMenu, setShowNewGameMenu] = useState(false);
  const disableNewGameMenu = useCallback(() => setShowNewGameMenu(false), []);
  const enableNewGameMenu = useCallback(() => setShowNewGameMenu(true), []);
  return (
    <Page
      headline={
        showNewGameMenu ? <FormattedMessage {...messages.newGameMenuHeadline} /> : APP_TITLE
      }
      id="page-game-menu"
      onEscape={showNewGameMenu ? disableNewGameMenu : undefined}
      returnTo={showNewGameMenu || !hasSaveGame ? undefined : Url.Game}
    >
      {showNewGameMenu ? (
        <NewGameMenu onCancel={disableNewGameMenu} />
      ) : (
        <GameMenu hasSavedGame={hasSaveGame} onNewGameButtonClick={enableNewGameMenu} />
      )}
    </Page>
  );
};

export default MenuPage;
