import { Chunk, ChunkKey, ChunkMap } from "../../data/types";
import { clear } from "../database/clear";
import { read } from "../database/read";
import { write } from "../database/write";
import { TableName } from "../enums/TableName";

export const clearChunks = async () => {
  await clear(TableName.Chunks);
};

export const readChunk = async (key: ChunkKey) => {
  return await read<Chunk>(TableName.Chunks, key);
};

export const writeChunks = async (chunks: ChunkMap) => {
  const chunkIds = Object.keys(chunks);
  for (let i = 0, length = chunkIds.length; i < length; i++) {
    const chunkId = chunkIds[i];
    await write(TableName.Chunks, chunkId, chunks[chunkId]);
  }
};
