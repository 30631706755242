import { Action } from "redux";
import { isType } from "typescript-fsa";
import { initialize, update } from "./actions";
import {
  DEFAULT_COLOR_THEME_NAME,
  DEFAULT_KEY_MAP_NAME,
  DEFAULT_MAP_ZOOM,
  DEFAULT_TILE_SET_NAME,
  DEFAULT_VIBRATION_INTENSITY
} from "./constants";
import { ToggleName } from "./enums/ToggleName";
import { State } from "./types";

const initialState: State = {
  colorThemeName: DEFAULT_COLOR_THEME_NAME,
  isInitialized: false,
  keyMapName: DEFAULT_KEY_MAP_NAME,
  mapZoom: DEFAULT_MAP_ZOOM,
  tileSetName: DEFAULT_TILE_SET_NAME,
  toggles: { [ToggleName.SecondaryControls]: false },
  vibrationIntensity: DEFAULT_VIBRATION_INTENSITY
};

export const reducer = (state: State = initialState, action: Action): State => {
  if (isType(action, initialize)) {
    return {
      ...state,
      isInitialized: true,
      ...action.payload.settings
    };
  }

  if (isType(action, update)) {
    const {
      setting: { name, value }
    } = action.payload;
    return {
      ...state,
      ...{ [name]: value }
    };
  }
  return state;
};
