import { Action } from "redux";
import { fork, put, take } from "redux-saga/effects";
import { isType } from "typescript-fsa";
import * as actions from "./actions";
import { MODULE_NAME } from "./constants";
import * as requests from "./requests";
import { WorldParameters, WorldResponse } from "./types";

function* getWorld(parameters: WorldParameters) {
  const world: void | WorldResponse = yield requests.getWorld(parameters);
  if (world) {
    yield put(actions.getWorld.done({ params: { parameters }, result: { world } }));
  } else {
    yield put(actions.getWorld.failed({ params: { parameters }, error: {} }));
  }
}

export function* saga() {
  while (true) {
    const action = yield take((a: Action) => (a.type as string).startsWith(MODULE_NAME));
    if (isType(action, actions.getWorld.started)) {
      yield fork(getWorld, action.payload.parameters);
    }
  }
}
