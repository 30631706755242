import { Entity, EntityId, EntityMap } from "../../model/types";
import { clear } from "../database/clear";
import { read } from "../database/read";
import { readKeys } from "../database/readKeys";
import { write } from "../database/write";
import { TableName } from "../enums/TableName";

export const clearEntities = async () => {
  await clear(TableName.Entities);
};

export const readEntities = async () => {
  const entities: EntityMap = {};
  const ids = await readEntityIds();
  for (let i = 0, length = ids.length; i < length; i++) {
    const id = ids[i];
    entities[id] = (await readEntity(id)) as Entity;
  }
  return entities;
};

export const readEntity = async (id: EntityId) => {
  return await read<Entity>(TableName.Entities, id);
};

export const readEntityIds = async () => {
  return await readKeys<EntityId>(TableName.Entities);
};

export const writeEntities = async (entities: EntityMap) => {
  const ids = Object.keys(entities);
  for (let i = 0, length = ids.length; i < length; i++) {
    const id = ids[i];
    await writeEntity(entities[id]);
  }
};

export const writeEntity = async (entity: Entity) => {
  await write(TableName.Entities, entity.id, entity);
};
