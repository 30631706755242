import actionCreatorFactory from "typescript-fsa";
import { ToggleName } from "../settings/enums/ToggleName";
import { MODULE_NAME } from "./constants";
import { Direction } from "./enums/Direction";

const action = actionCreatorFactory(MODULE_NAME);

export const mapZoomDecrease = action("MAP_ZOOM_DECREASE");

export const mapZoomIncrease = action("MAP_ZOOM_INCREASE");

export const mapZoomReset = action("MAP_ZOOM_RESET");

export const resetTriggerState = action<{ controlId: string }>("RESET_TRIGGER_STATE");

export const requestMovement = action<{ direction: Direction }>("REQUEST_MOVEMENT");

export const setActiveControlId = action<{ controlId?: string; forceChange?: boolean }>(
  "SET_ACTIVE_CONTROL_ID"
);

export const toggle = action<{ toggleName: ToggleName }>("TOGGLE");

export const triggerControl = action<{
  controlId: string;
  callback: void | VoidFunction;
}>("TRIGGER_CONTROL");

export const updateActiveControlSettings = action<{ isGuaranteed?: boolean; isLocked?: boolean }>(
  "UPDATE_ACTIVE_CONTROL_SETTINGS"
);
