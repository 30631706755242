import { useLayoutEffect, useRef, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";
import { createSize } from "../../core/factories/size";
import { ElementRef, Size } from "../../core/types";

interface Options {
  onResize?: (size: Size) => void;
}

export const useComponentSize = (ref: ElementRef, options: Options = {}) => {
  const [size, setSize] = useState(createSize(0, 0));
  const initialized = useRef(false);
  const observers = useRef(
    new ResizeObserver(entries =>
      entries.forEach(entry => {
        const { height, width } = entry.contentRect;
        const newSize = createSize(width, height);
        setSize(newSize);
        if (options.onResize) {
          options.onResize(newSize);
        }
      })
    )
  );
  useLayoutEffect(() => {
    if (ref.current) {
      observers.current.observe(ref.current);
      initialized.current = true;
      return () => {
        observers.current.unobserve(ref.current as HTMLElement);
      };
    }
  }, []);
  return size;
};
