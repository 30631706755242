import { defineMessages, Messages } from "react-intl";

export const messages: Messages = defineMessages({
  createSavegame: {
    defaultMessage: "Creating new Savegame",
    description: "The title of the CreateSavegame task",
    id: "task.createSavegame"
  },
  loadSavegame: {
    defaultMessage: "Loading Savegame",
    description: "The title of the LoadSavegame task",
    id: "task.loadSavegame"
  }
});
