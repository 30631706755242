import { ColorName } from "../../core/enums/ColorName";
import { SpriteName } from "../../core/enums/SpriteName";
import definitions from "../definitions/cells.json";
import { CellType } from "../enums/CellType";
import { CellTemplates } from "../types";

export const createCellsTemplates = (): CellTemplates => {
  for (const type of Object.values(CellType)) {
    const definition = (definitions as CellTemplates)[type];
    if (!definition) {
      throw new Error(`Cell definition '${type}' is not defined in 'core/definitions/cells.json'`);
    }
    if (!Object.values(ColorName).includes(definition.color)) {
      throw new Error(`'${definition.color}' is is not a valid color value`);
    }
    if (!Object.values(SpriteName).includes(definition.sprite)) {
      throw new Error(`'${definition.sprite}' is not a valid sprite value`);
    }
  }
  return definitions as CellTemplates;
};
