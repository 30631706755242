import { useSelector } from "../../core/hooks/useSelector";
import { getClientSize, getFormFactor, getIsMobile, getIsResizing } from "../selectors";

export const useClientSize = () => useSelector(getClientSize);

export const useFormFactor = () => useSelector(getFormFactor);

export const useIsMobile = () => useSelector(getIsMobile);

export const useIsResizing = () => useSelector(getIsResizing);
