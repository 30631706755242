import { applyMiddleware, compose, createStore } from "redux";
import { SAGA_MIDDLEWARE } from "../constants";
import { reducer } from "../reducer";

const composeEnhancers =
  (process.env.NODE_ENV === "development" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const createReduxStore = () =>
  createStore(reducer, composeEnhancers(applyMiddleware(SAGA_MIDDLEWARE)));
