import { fork, put } from "redux-saga/effects";
import { saga as apiSaga } from "../api/saga";
import { saga as dataSaga } from "../data/saga";
import { saga as measuringSaga } from "../measuring/saga";
import { saga as notificationsSaga } from "../notifications/saga";
import { saga as settingsSaga } from "../settings/saga";
import { saga as userInputSaga } from "../userInput/saga";
import { sagasStarted } from "./actions";

export function* saga() {
  yield put(sagasStarted());
  yield fork(apiSaga);
  yield fork(measuringSaga);
  yield fork(settingsSaga);
  yield fork(notificationsSaga);
  yield fork(userInputSaga);
  yield fork(dataSaga);
}
