import { Coordinates } from "../../model/types";
import { ChunkOrigin } from "../types";

export const currentChunkOrigins = (
  coordinates: Coordinates,
  chunkSize: number,
  chunkRadius: number = 0
) => {
  const centerX = Math.floor(coordinates.x / chunkSize);
  const centerY = Math.floor(coordinates.y / chunkSize);
  const chunkOrigins: ChunkOrigin[] = [];
  for (let y = centerY - chunkRadius; y <= centerY + chunkRadius; y++) {
    for (let x = centerX - chunkRadius; x <= centerX + chunkRadius; x++) {
      chunkOrigins.push({ mapId: coordinates.mapId, x, y });
    }
  }
  return chunkOrigins;
};
