export enum Direction {
  Down = "down",
  DownLeft = "down-left",
  DownRight = "down-right",
  Left = "left",
  Right = "right",
  Up = "up",
  UpLeft = "up-left",
  UpRight = "up-right"
}
