import { TableName } from "../enums/TableName";
import { openDb } from "./open";

export const read = async <T>(table: TableName, key: number | string) => {
  const db = await openDb();
  const tx = db.transaction(table);
  const store = tx.objectStore(table);
  const item: void | T = await store.get(key);
  return item;
};
