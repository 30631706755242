import { SettingName } from "../../settings/enums/SettingName";
import { Setting, Settings } from "../../settings/types";
import { read as dbRead } from "../database/read";
import { write as dbWrite } from "../database/write";
import { TableName } from "../enums/TableName";

export const readSettings = async () => {
  const settings = {} as any;
  for (const settingName of Object.values(SettingName) as string[]) {
    const setting = await dbRead(TableName.Settings, settingName);
    if (setting) {
      settings[settingName] = setting;
    }
  }
  return settings as Settings;
};

export const writeSetting = async (setting: Setting) => {
  await dbWrite(TableName.Settings, setting.name, setting.value);
};
