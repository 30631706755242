/**
 * UseInterval follows the pattern by Dan Abramov demonstrated here:
 *  https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */

import { useEffect, useRef } from "react";

export const useInterval = (callback: VoidFunction, delay: number) => {
  const savedCallback = useRef<undefined | VoidFunction>(undefined);
  useEffect(() => {
    savedCallback.current = callback;
  });
  useEffect(() => {
    const tick = () => (savedCallback as any).current();
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};
