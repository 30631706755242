import { RefObject } from "react";
import { useMapControls } from "../../../userInput/hooks/useMapControls";
import { useTouch } from "../../../userInput/hooks/useTouch";
import { PinchData } from "../../../userInput/types";

export const useTouchCommands = (
  innerRef: RefObject<null | HTMLDivElement>,
  outerRef: RefObject<null | HTMLDivElement>
) => {
  const { decreaseZoom, increaseZoom } = useMapControls();
  const handlePinch = (e: PinchData) => {
    const { distanceDiff } = e;
    if (distanceDiff < 0) {
      increaseZoom();
    } else {
      decreaseZoom();
    }
  };
  useTouch(outerRef, { onPinch: handlePinch });
  useTouch(innerRef, {});
};
