import { createSelector } from "reselect";
import { State } from "../core/types";
import { MODULE_NAME } from "./constants";
import { calculateFormFactor } from "./utils/calculateFormFactor";
import { calculateIsMobile } from "./utils/calculateIsMobile";

export const getClientSize = (state: State) => state[MODULE_NAME].clientSize;

export const getIsResizing = (state: State) => state[MODULE_NAME].isResizing;

export const getFormFactor = createSelector(
  getClientSize,
  calculateFormFactor
);

export const getIsMobile = createSelector(
  getFormFactor,
  calculateIsMobile
);
