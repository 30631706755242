import { useKeyCommands } from "../../../settings/hooks/selectorHooks";
import { CommandName } from "../../../userInput/enums/CommandName";
import { Direction } from "../../../userInput/enums/Direction";
import { useKeyboard } from "../../../userInput/hooks/useKeyboard";
import { useMapControls } from "../../../userInput/hooks/useMapControls";
import { usePlayerAction } from "../../../userInput/hooks/usePlayerAction";
import { useSecondaryControls } from "../../../userInput/hooks/useSecondaryControls";
import { useTrigger } from "../../../userInput/hooks/useTrigger";
import { KeyEvent } from "../../../userInput/types";
import { isKeyCommand } from "../../../userInput/utils/isKeyCommand";

export const useKeyboardCommands = () => {
  const [, toggleSecondaryControls] = useSecondaryControls();
  const [, trigger] = useTrigger();
  const { move } = usePlayerAction();
  const { decreaseZoom, increaseZoom, resetZoom } = useMapControls();
  const keyCommands = useKeyCommands();
  const handleKeyDown = (event: KeyEvent) => {
    const is = (name: CommandName) => isKeyCommand(keyCommands[name], event);

    // Map zoom
    if (is(CommandName.MapZoomIncrease)) {
      trigger(CommandName.MapZoomIncrease, increaseZoom);
    } else if (is(CommandName.MapZoomDecrease)) {
      trigger(CommandName.MapZoomDecrease, decreaseZoom);
    } else if (is(CommandName.MapZoomReset)) {
      trigger(CommandName.MapZoomReset, resetZoom);
    }

    // Toggle UI elements
    else if (is(CommandName.ToggleSecondaryControls)) {
      trigger(CommandName.ToggleSecondaryControls, toggleSecondaryControls);
    }

    // Keyboard movement
    else if (is(CommandName.MoveDown)) {
      move(Direction.Down);
    } else if (is(CommandName.MoveDownLeft)) {
      move(Direction.DownLeft);
    } else if (is(CommandName.MoveDownRight)) {
      move(Direction.DownRight);
    } else if (is(CommandName.MoveLeft)) {
      move(Direction.Left);
    } else if (is(CommandName.MoveRight)) {
      move(Direction.Right);
    } else if (is(CommandName.MoveUp)) {
      move(Direction.Up);
    } else if (is(CommandName.MoveUpLeft)) {
      move(Direction.UpLeft);
    } else if (is(CommandName.MoveUpRight)) {
      move(Direction.UpRight);
    }
  };
  useKeyboard({ onKeyDown: handleKeyDown });
};
