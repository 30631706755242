import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "../../../core/components/Button";
import LinkButton from "../../../core/components/LinkButton";
import Menu from "../../../core/components/Menu";
import { Url } from "../../enums/Url";
import { messages } from "./messages";
import "./styles.css";

interface Props {
  hasSavedGame: boolean;
  onNewGameButtonClick: VoidFunction;
}

const GameMenu: React.FunctionComponent<Props> = ({ hasSavedGame, onNewGameButtonClick }) => (
  <Menu id="game-menu">
    {hasSavedGame ? (
      <LinkButton id="game-menu-continue-game-button" target={Url.Game}>
        <FormattedMessage {...messages.continueButtonLabel} />
      </LinkButton>
    ) : (
      <></>
    )}
    <Button id="game-menu-start-game-button" onUse={onNewGameButtonClick}>
      <FormattedMessage {...messages.startNewGameButtonLabel} />
    </Button>
    <LinkButton id="game-menu-start-settings-button" target={Url.Settings}>
      <FormattedMessage {...messages.settingsButtonLabel} />
    </LinkButton>
    <LinkButton id="game-menu-back-button" target={Url.Home}>
      <FormattedMessage {...messages.backButtonLabel} />
    </LinkButton>
  </Menu>
);

export default GameMenu;
