import { Sprite } from "pixi.js";
import { DEFAULT_TILE_SIZE } from "../constants";
import { Tile, TileConfiguration } from "../types";
import { createPoint } from "./point";

export const createTile = (config: TileConfiguration) => {
  const tile = new Sprite(PIXI.utils.TextureCache[`${config.spriteName}.png`]) as Tile;
  tile.name = config.name;
  tile.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;
  tile.alpha = config.alpha || 1;
  tile.tint = config.tint ? config.tint.asNumber : 0xffffff;
  tile.width = DEFAULT_TILE_SIZE;
  tile.height = DEFAULT_TILE_SIZE;
  tile.x = config.mapPosition.x * DEFAULT_TILE_SIZE;
  tile.y = config.mapPosition.y * DEFAULT_TILE_SIZE;
  tile.mapPosition = config.mapPosition;
  tile.resize = size => {
    const { x, y } = tile.mapPosition;
    tile.width = size.width;
    tile.height = size.height;
    tile.x = x * size.width;
    tile.y = y * size.height;
  };
  tile.moveTo = (x, y) => {
    tile.x = x * tile.width;
    tile.y = y * tile.height;
    tile.mapPosition = createPoint(x, y);
  };
  return tile;
};
