import { createBoundingBox } from "../../../core/factories/boundingBox";
import { createPoint } from "../../../core/factories/point";
import { Size, TileSet } from "../../../core/types";
import { isEven } from "../../../core/utils/isEven";
import { Coordinates, Entity } from "../../../model/types";
import { getCoordinates } from "../../../model/utils/getComponent";

export const calculateBoundaries = (
  focus: void | Coordinates,
  mapSize: Size,
  player: Entity,
  mapZoom: number,
  pixelSize: Size,
  tileSet: TileSet
) => {
  const { height: pixelHeight, width: pixelWidth } = pixelSize;
  const { height: tileHeight, width: tileWidth } = tileSet.tileSize;
  const { x, y } = (focus || getCoordinates(player))!;
  const maxWidth = Math.floor(pixelWidth / (tileWidth + mapZoom));
  const maxHeight = Math.floor(pixelHeight / (tileHeight + mapZoom));
  const mapWidth = mapSize.width;
  const mapHeight = mapSize.height;
  const radiusX = Math.floor((maxWidth - (isEven(maxWidth) ? 1 : 0)) / 2);
  const radiusY = Math.floor((maxHeight - (isEven(maxHeight) ? 1 : 0)) / 2);
  let xMin = x - radiusX;
  let xMax = x + radiusX;
  let yMin = y - radiusY;
  let yMax = y + radiusY;
  let offsetX = 0 - xMin;
  let offsetY = 0 - yMin;
  if (maxWidth >= mapWidth) {
    // tileMap width bigger than map width
    xMin = 0;
    xMax = mapWidth - 1;
    offsetX = 0;
  } else if (xMin <= 0) {
    // focus near the left border
    xMin = 0;
    xMax = maxWidth - 1;
    offsetX = 0;
  } else if (xMax >= mapWidth - 1) {
    // focus near the right border
    xMin = mapWidth - maxWidth;
    xMax = mapWidth - 1;
    offsetX = 0 - xMin;
  }
  if (maxHeight >= mapHeight) {
    // tileMap height bigger than map height
    yMin = 0;
    yMax = mapHeight - 1;
    offsetY = 0;
  } else if (yMin <= 0) {
    // focus near the top border
    yMin = 0;
    yMax = maxHeight - 1;
    offsetY = 0;
  } else if (yMax >= mapHeight - 1) {
    // focus near the bottom border
    yMin = mapHeight - maxHeight;
    yMax = mapHeight - 1;
    offsetY = 0 - yMin;
  }
  return {
    boundingBox: createBoundingBox(xMin, xMax, yMin, yMax),
    offset: createPoint(offsetX, offsetY)
  };
};
