import React from "react";
import "./styles.css";

interface Props {
  autofocus?: boolean;
  children: JSX.Element;
  label?: string | JSX.Element;
  tabIndex?: number;
}

const LabeledControl: React.FunctionComponent<Props> = ({ children, label, ...otherProps }) => (
  <div className="labeled-control">
    <div className="labeled-control-label">{label}</div>
    {React.cloneElement(children, { ...otherProps })}
  </div>
);

LabeledControl.defaultProps = {
  label: ""
};

export default LabeledControl;
