import React from "react";
import { useColorStrings } from "../../../settings/hooks/colorHooks";
import { ColorName } from "../../enums/ColorName";
import "./styles.css";

interface Props {
  color?: ColorName;
  progress: number;
}

const ProgressBar: React.FunctionComponent<Props> = ({ color, progress }) => {
  const [fill, stroke] = useColorStrings(ColorName.Text, ColorName.Text);
  return (
    <div className="progress-bar">
      <svg width="100%" height="1em">
        <rect width={`${progress * 10}px`} height="100%" style={{ fill }} />
        <rect
          width="100%"
          height="100%"
          style={{
            fillOpacity: 0,
            stroke
          }}
        />
      </svg>
    </div>
  );
};

export default ProgressBar;
