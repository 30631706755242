import { createPoint } from "../../core/factories/point";
import { calculateDistance } from "../../core/utils/calculateDistance";
import { MOUSE_CLICK_MOVEMENT_THRESHOLD, MOUSE_PAN_MOVEMENT_THRESHOLD } from "../constants";
import { GestureName } from "../enums/GestureName";
import { Click, Gesture, MouseGestureEvent, Pan } from "../types";

const getPan = (current: MouseGestureEvent, previous: void | MouseGestureEvent): void | Pan => {
  if (!current.direction) {
    return;
  }
  if (!previous) {
    previous = current;
  }
  const distance = calculateDistance(
    current.x,
    current.y,
    current.startPoint.x,
    current.startPoint.y
  );
  const diffDistance = calculateDistance(current.x, current.y, previous.x, previous.y);
  if (diffDistance < MOUSE_PAN_MOVEMENT_THRESHOLD) {
    return;
  }
  const timeDiff = current.time - previous.time;
  return {
    data: {
      current: createPoint(current.x, current.y),
      diffDistance,
      diffX: current.x - previous.x,
      diffY: current.y - previous.y,
      direction: current.direction,
      distance,
      duration: current.time - current.startTime,
      origin: createPoint(current.startPoint.x, current.startPoint.y),
      velocity: diffDistance / timeDiff
    },
    name: GestureName.Pan
  };
};

const getClick = (current: MouseGestureEvent): void | Click => {
  const { x, y, startPoint, startTime } = current;
  const distance = calculateDistance(x, y, startPoint.x, startPoint.y);
  if (distance > MOUSE_CLICK_MOVEMENT_THRESHOLD) {
    return;
  }
  return {
    data: {
      location: createPoint(startPoint.x, startPoint.y),
      startTime
    },
    name: GestureName.Click
  };
};

export const createMouseGesture = (
  current: MouseGestureEvent,
  previous?: void | MouseGestureEvent
): void | Gesture => {
  const click = getClick(current);
  if (click) {
    return click;
  }
  const pan = getPan(current, previous);
  if (pan) {
    return pan;
  }
};
