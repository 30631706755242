export const joinStrings = (strings: Array<void | string | string[]>): string => {
  const result: string[] = [];
  for (let i = 0, len = strings.length; i < len; i++) {
    const str = strings[i];
    if (Array.isArray(str)) {
      result.push(...str);
    } else if (str && str.length > 0) {
      result.push(str);
    }
  }
  return result.join(" ");
};
