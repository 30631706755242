import React from "react";
import { Route, Switch } from "react-router";
import { ColorName } from "../../../core/enums/ColorName";
import { useColorStrings } from "../../../settings/hooks/colorHooks";
import { useFont, useIsInitialized } from "../../../settings/hooks/selectorHooks";
import RouteOrActiveTasks from "../../../tasks/components/RouteOrActiveTasks";
import { Url } from "../../enums/Url";
import GamePage from "../GamePage";
import HomePage from "../HomePage";
import MenuPage from "../MenuPage";
import NotFoundPage from "../NotFoundPage";
import Notifications from "../Notifications";
import SettingsPage from "../SettingsPage";
import "./styles.css";

const App: React.FunctionComponent = () => {
  const [backgroundColor, color] = useColorStrings(ColorName.Background, ColorName.Text);
  const font = useFont();
  const isInitialized = useIsInitialized();
  return isInitialized ? (
    <div
      id="app"
      style={{
        backgroundColor,
        color,
        fontFamily: font.fontFamily
      }}
    >
      <Notifications />
      <Switch>
        <Route component={HomePage} exact={true} path={Url.Home} />
        <RouteOrActiveTasks component={MenuPage} path={Url.Menu} />
        <RouteOrActiveTasks component={GamePage} path={Url.Game} />
        <RouteOrActiveTasks component={SettingsPage} path={Url.Settings} />
        <Route component={NotFoundPage} />
      </Switch>
    </div>
  ) : (
    <></>
  );
};

export default App;
