import { createPoint } from "../../core/factories/point";
import { calculateDistance } from "../../core/utils/calculateDistance";
import {
  TOUCH_PAN_MOVEMENT_THRESHOLD,
  TOUCH_PINCH_MOVEMENT_THRESHOLD,
  TOUCH_TAP_MOVEMENT_THRESHOLD
} from "../constants";
import { GestureName } from "../enums/GestureName";
import { Gesture, Pan, Pinch, Tap, TouchGestureEvent } from "../types";

const getPan = (current: TouchGestureEvent[], previous: TouchGestureEvent[]): void | Pan => {
  if (current.length > 1) {
    return;
  }
  const [curr] = current;
  const [prev] = previous;
  if (!curr.direction) {
    return;
  }
  const distance = calculateDistance(curr.x, curr.y, curr.startPoint.x, curr.startPoint.y);
  const diffDistance = calculateDistance(curr.x, curr.y, prev.x, prev.y);
  if (diffDistance < TOUCH_PAN_MOVEMENT_THRESHOLD) {
    return;
  }
  const timeDiff = curr.time - prev.time;
  return {
    data: {
      current: createPoint(curr.x, curr.y),
      diffDistance,
      diffX: curr.x - prev.x,
      diffY: curr.y - prev.y,
      direction: curr.direction,
      distance,
      duration: curr.time - curr.startTime,
      origin: createPoint(curr.startPoint.x, curr.startPoint.y),
      velocity: diffDistance / timeDiff
    },
    name: GestureName.Pan
  };
};

const getPinch = (current: TouchGestureEvent[], previous: TouchGestureEvent[]): void | Pinch => {
  const currA = current[0];
  const currB = current[1];
  if ((previous.length !== 2 && current.length !== 2) || !currA || !currB) {
    return;
  }
  const prevA = previous.find(e => e.id === currA.id);
  const prevB = previous.find(e => e.id === currB.id);
  if (prevA === undefined || prevB === undefined) {
    return;
  }
  const prevDistance = calculateDistance(prevA.x, prevA.y, prevB.x, prevB.y);
  const currDistance = calculateDistance(currA.x, currA.y, currB.x, currB.y);
  const distanceDiff = prevDistance - currDistance;
  if (Math.abs(distanceDiff) < TOUCH_PINCH_MOVEMENT_THRESHOLD) {
    return;
  }
  const timeDiff = currA.time - prevA.time;
  return {
    data: {
      distanceDiff,
      velocity: Math.abs(distanceDiff) / timeDiff
    },
    name: GestureName.Pinch
  };
};

const getTap = (current: TouchGestureEvent[]): void | Tap => {
  if (current.length > 1) {
    return;
  }
  const { x, y, startPoint, startTime } = current[0];
  const distance = calculateDistance(x, y, startPoint.x, startPoint.y);
  if (distance > TOUCH_TAP_MOVEMENT_THRESHOLD) {
    return;
  }
  return {
    data: {
      location: createPoint(startPoint.x, startPoint.y),
      startTime
    },
    name: GestureName.Tap
  };
};

export const createTouchGesture = (
  current: TouchGestureEvent[],
  previous: TouchGestureEvent[]
): void | Gesture => {
  const tap = getTap(current);
  if (tap) {
    return tap;
  }
  const pan = getPan(current, previous);
  if (pan) {
    return pan;
  }
  const pinch = getPinch(current, previous);
  if (pinch) {
    return pinch;
  }
};
