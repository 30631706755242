import fontDefinitions from "../definitions/fonts.json";
import { FontName } from "../enums/FontName";
import { FontDefinitions, FontsJson } from "../types";
import { createFont } from "./font";

export const createFonts = () => {
  const result: FontDefinitions = {};
  for (const fontName of Object.values(FontName)) {
    const definition = (fontDefinitions as FontsJson)[fontName];
    if (!definition) {
      throw new Error(
        `No definition for font '${fontName}' found in 'core/definitions/fonts.json'`
      );
    }
    const { fontFamily } = definition;
    if (!fontFamily) {
      throw new Error(
        `Incomplete definition for font '${fontName}' found in 'core/definitions/fonts.json'`
      );
    }
    result[fontName] = createFont(fontFamily);
  }
  return result;
};
