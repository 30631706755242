import { useCallback } from "react";
import { useDispatch } from "redux-react-hook";
import { useSelector } from "../../core/hooks/useSelector";
import { useVibrationIntensity } from "../../settings/hooks/stateHooks";
import { triggerControl } from "../actions";
import { getTriggerStates } from "../selectors";
import { vibrate } from "../utils/vibrate";

type ReturnType = [
  (controlId: string) => boolean,
  (controlId: string, callback?: VoidFunction) => void
];

export const useTrigger = (): ReturnType => {
  const dispatch = useDispatch();
  const [vibrationIntensity] = useVibrationIntensity();
  const states = useSelector(getTriggerStates);
  const isTriggered = useCallback((controlId: string) => states[controlId] || false, [states]);
  const trigger = useCallback(
    (controlId: string, callback?: VoidFunction) => {
      if (callback) {
        dispatch(triggerControl({ controlId, callback }));
      }
      vibrate(vibrationIntensity);
    },
    [vibrationIntensity]
  );
  return [isTriggered, trigger];
};
