import { RefObject } from "react";
import { useMapControls } from "../../../userInput/hooks/useMapControls";
import { useMouse } from "../../../userInput/hooks/useMouse";
import { WheelData } from "../../../userInput/types";

export const useMouseCommands = (innerRef: RefObject<null | HTMLDivElement>) => {
  const { decreaseZoom, increaseZoom } = useMapControls();
  const handleMouseWheel = (e: WheelData) => {
    const { deltaY } = e;
    if (deltaY < 0) {
      increaseZoom();
    } else {
      decreaseZoom();
    }
  };
  useMouse(innerRef, {
    onWheel: handleMouseWheel
  });
};
