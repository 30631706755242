import { messages } from "../definitions/messages";
import { severities } from "../definitions/severities";
import { types } from "../definitions/types";
import { NotificationName } from "../enums/NotificationName";
import { NotificationDefinitions } from "../types";

export const createNotificationDefinitions = (): NotificationDefinitions => {
  const definitions: NotificationDefinitions = {};
  for (const name of Object.values(NotificationName)) {
    const text = messages[name];
    const severity = severities[name];
    const type = types[name];
    definitions[name] = {
      severity,
      text,
      type
    };
  }
  return definitions;
};
