import { delay, fork, put, select, take } from "redux-saga/effects";
import { isType } from "typescript-fsa";
import { update } from "../settings/actions";
import { DEFAULT_MAP_ZOOM } from "../settings/constants";
import { SettingName } from "../settings/enums/SettingName";
import { getMapZoom, getToggles } from "../settings/selectors";
import {
  mapZoomDecrease,
  mapZoomIncrease,
  mapZoomReset,
  resetTriggerState,
  toggle,
  triggerControl
} from "./actions";
import { CONTROL_TRIGGER_DELAY, MAP_ZOOM_INCREMENT, MAP_ZOOM_MAX, MAP_ZOOM_MIN } from "./constants";

function* handleControlTrigger(controlId: string, callback: void | VoidFunction) {
  yield delay(CONTROL_TRIGGER_DELAY);
  if (callback) {
    callback();
  }
  yield put(
    resetTriggerState({
      controlId
    })
  );
}

export function* saga() {
  while (true) {
    const action = yield take("*");

    if (isType(action, mapZoomDecrease)) {
      const mapZoom = yield select(getMapZoom);
      const newMapZoom = mapZoom - MAP_ZOOM_INCREMENT;
      if (newMapZoom > MAP_ZOOM_MIN) {
        yield put(
          update({
            setting: { name: SettingName.MapZoom, value: newMapZoom }
          })
        );
      }
    }

    if (isType(action, mapZoomIncrease)) {
      const mapZoom = yield select(getMapZoom);
      const newMapZoom = mapZoom + MAP_ZOOM_INCREMENT;
      if (newMapZoom < MAP_ZOOM_MAX) {
        yield put(
          update({
            setting: { name: SettingName.MapZoom, value: newMapZoom }
          })
        );
      }
    }

    if (isType(action, mapZoomReset)) {
      yield put(
        update({
          setting: { name: SettingName.MapZoom, value: DEFAULT_MAP_ZOOM }
        })
      );
    }

    if (isType(action, toggle)) {
      const { toggleName } = action.payload;
      const toggles = yield select(getToggles);
      toggles[toggleName] = !toggles[toggleName];
      yield put(
        update({
          setting: { name: SettingName.Toggles, value: { ...toggles } }
        })
      );
    }

    if (isType(action, triggerControl)) {
      const { controlId, callback } = action.payload;
      yield fork(handleControlTrigger, controlId, callback);
    }
  }
}
