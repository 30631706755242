import { Action } from "redux";
import { isType } from "typescript-fsa";
import {
  resetTriggerState,
  setActiveControlId,
  triggerControl,
  updateActiveControlSettings
} from "./actions";
import { State } from "./types";

const initialState: State = {
  activeControl: {
    controlId: undefined,
    isGuaranteed: false,
    isLocked: false
  },
  triggerStates: {}
};

export const reducer = (state: State = initialState, action: Action): State => {
  if (isType(action, resetTriggerState)) {
    return {
      ...state,
      triggerStates: {
        ...state.triggerStates,
        [action.payload.controlId]: undefined
      }
    };
  }

  if (isType(action, setActiveControlId)) {
    const { isGuaranteed, isLocked } = state.activeControl;
    const { controlId, forceChange } = action.payload;
    const isReset = controlId === undefined;
    return !forceChange && (isLocked || (isReset && isGuaranteed))
      ? state
      : {
          ...state,
          activeControl: { ...state.activeControl, controlId }
        };
  }

  if (isType(action, triggerControl)) {
    return {
      ...state,
      triggerStates: {
        ...state.triggerStates,
        [action.payload.controlId]: true
      }
    };
  }

  if (isType(action, updateActiveControlSettings)) {
    return {
      ...state,
      activeControl: {
        ...state.activeControl,
        ...action.payload
      }
    };
  }

  return state;
};
