import { useCallback } from "react";
import { useDispatch } from "redux-react-hook";
import { ColorThemeName } from "../../core/enums/ColorThemeName";
import { TileSetName } from "../../core/enums/TileSetName";
import { useSelector } from "../../core/hooks/useSelector";
import { KeyMapName } from "../../userInput/enums/KeyMapName";
import { VibrationIntensity } from "../../userInput/enums/VibrationIntensity";
import { update } from "../actions";
import { SettingName } from "../enums/SettingName";
import {
  getColorThemeName,
  getKeyMapName,
  getMapZoom,
  getTileSetName,
  getVibrationIntensity
} from "../selectors";
import { SettingsHook } from "../types";

const useUpdate = (settingName: SettingName) => {
  const dispatch = useDispatch();
  return useCallback((value: any) => dispatch(update({ setting: { name: settingName, value } })), [
    settingName
  ]);
};

export const useColorThemeName = (): SettingsHook<ColorThemeName> => [
  useSelector(getColorThemeName),
  useUpdate(SettingName.ColorThemeName)
];

export const useKeyMapName = (): SettingsHook<KeyMapName> => [
  useSelector(getKeyMapName),
  useUpdate(SettingName.KeyMapName)
];

export const useMapZoom = (): SettingsHook<number> => [
  useSelector(getMapZoom),
  useUpdate(SettingName.MapZoom)
];

export const useTileSetName = (): SettingsHook<TileSetName> => [
  useSelector(getTileSetName),
  useUpdate(SettingName.TileSetName)
];

export const useVibrationIntensity = (): SettingsHook<VibrationIntensity> => [
  useSelector(getVibrationIntensity),
  useUpdate(SettingName.VibrationIntensity)
];
