import { useCallback } from "react";
import { useDispatch } from "redux-react-hook";
import { mapZoomDecrease, mapZoomIncrease, mapZoomReset } from "../actions";

export const useMapControls = () => {
  const dispatch = useDispatch();
  const decreaseZoom = useCallback(() => dispatch(mapZoomDecrease()), []);
  const increaseZoom = useCallback(() => dispatch(mapZoomIncrease()), []);
  const resetZoom = useCallback(() => dispatch(mapZoomReset()), []);
  return {
    decreaseZoom,
    increaseZoom,
    resetZoom
  };
};
