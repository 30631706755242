import { useSelector } from "../../core/hooks/useSelector";
import {
  getCellTypeCache,
  getChunkSize,
  getCoordinatesCache,
  getEntityCache,
  getHasSavegame,
  getIsSynched,
  getIsSynching,
  getMapId,
  getMapMeta,
  getMapSize,
  getPlayer,
  getPlayerId,
  getSeed,
  getTimestamp,
  getTurn
} from "../selectors";

export const useCellTypeCache = () => useSelector(getCellTypeCache);

export const useChunkSize = () => useSelector(getChunkSize);

export const useEntityCache = () => useSelector(getEntityCache);

export const useIsSynched = () => useSelector(getIsSynched);

export const useIsSynching = () => useSelector(getIsSynching);

export const useCoordinatesCache = () => useSelector(getCoordinatesCache);

export const useMapMeta = () => useSelector(getMapMeta);

export const usePlayerId = () => useSelector(getPlayerId);

export const useSeed = () => useSelector(getSeed);

export const useTimestamp = () => useSelector(getTimestamp);

export const useTurn = () => useSelector(getTurn);

export const useHasSavegame = () => useSelector(getHasSavegame);

export const usePlayer = () => useSelector(getPlayer);

export const useMapId = () => useSelector(getMapId);

export const useMapSize = () => useSelector(getMapSize);
