import { useCallback, useEffect, useState } from "react";
import { ElementRef } from "../../core/types";
import { stopEvent } from "../../core/utils/stopEvent";
import { MOUSE_DOUBLE_CLICK_MAX_DURATION } from "../constants";
import { GestureName } from "../enums/GestureName";
import { MouseButton } from "../enums/MouseButton";
import { createGestureEvent } from "../factories/gestureEvent";
import { createMouseGesture } from "../factories/mouseGesture";
import {
  ClickCallback,
  ClickData,
  Gesture,
  MouseGestureEvent,
  PanCallback,
  PanData,
  WheelCallback
} from "../types";

interface Options {
  onDoubleClick?: void | ClickCallback;
  onLeftClick?: void | ClickCallback;
  onLeftPan?: void | PanCallback;
  onLeftPanEnd?: void | PanCallback;
  onMiddleClick?: void | ClickCallback;
  onMiddlePan?: void | PanCallback;
  onMiddlePanEnd?: void | PanCallback;
  onRightClick?: void | ClickCallback;
  onRightPan?: void | PanCallback;
  onRightPanEnd?: void | PanCallback;
  onWheel?: void | WheelCallback;
}

interface MouseState {
  doubleClickTimeout: void | NodeJS.Timeout;
  event: void | MouseGestureEvent;
  gesture: void | Gesture;
  lastEventEnd: number;
}

const activateCallbacks = (state: MouseState, options: Options, isEnd: boolean) => {
  const { doubleClickTimeout, event, gesture, lastEventEnd } = state;
  if (event && gesture) {
    const { data, name } = gesture;
    if (name === GestureName.Click && isEnd) {
      return onClick(event.button, lastEventEnd, doubleClickTimeout, data, options);
    }
    if (name === GestureName.Pan) {
      return onPan(event.button, data as PanData, options, isEnd);
    }
  }
};

const createMouseGestureEvent = (e: MouseEvent, previous?: MouseGestureEvent) => ({
  ...createGestureEvent(e, previous),
  button: previous ? previous.button : mapMouseButton(e.button)
});

const disableContextMenu = stopEvent;

const findCorrectGesture = (
  event: MouseGestureEvent,
  previousEvent: void | MouseGestureEvent,
  previousGesture: void | Gesture
) => {
  const gesture = createMouseGesture(event, previousEvent);
  if (gesture === undefined) {
    return previousGesture;
  }
  if (previousGesture === undefined) {
    return gesture;
  }
  return previousGesture.name === GestureName.Pan && gesture.name !== GestureName.Pan
    ? previousGesture
    : gesture;
};

const mapMouseButton = (n: number) =>
  n === 0 ? MouseButton.Left : n === 1 ? MouseButton.Middle : MouseButton.Right;

const onClick = (
  button: MouseButton,
  lastEventEnd: number,
  doubleClickTimeout: void | NodeJS.Timeout,
  data: ClickData,
  options: Options
) => {
  const now = new Date().getTime();
  const timeSinceLastClick = now - lastEventEnd;
  if (button === MouseButton.Middle && options.onMiddleClick) {
    options.onMiddleClick(data);
  } else if (button === MouseButton.Right && options.onRightClick) {
    options.onRightClick(data);
  } else if (
    timeSinceLastClick < MOUSE_DOUBLE_CLICK_MAX_DURATION &&
    doubleClickTimeout !== undefined
  ) {
    clearTimeout(doubleClickTimeout);
    if (options.onDoubleClick && button === MouseButton.Left) {
      options.onDoubleClick(data);
    }
  } else {
    if (options.onDoubleClick !== undefined) {
      return setTimeout(() => {
        if (options.onLeftClick) {
          options.onLeftClick(data);
        }
      }, MOUSE_DOUBLE_CLICK_MAX_DURATION + 1);
    } else {
      if (options.onLeftClick) {
        options.onLeftClick(data);
      }
    }
  }
};

const onPan = (button: MouseButton, data: PanData, options: Options, isEnd: boolean) => {
  if (isEnd) {
    if (options.onLeftPanEnd && button === MouseButton.Left) {
      options.onLeftPanEnd(data);
    } else if (options.onMiddlePanEnd && button === MouseButton.Middle) {
      options.onMiddlePanEnd(data);
    } else if (options.onRightPanEnd && button === MouseButton.Right) {
      options.onRightPanEnd(data);
    }
    return;
  }
  if (options.onLeftPan && button === MouseButton.Left) {
    options.onLeftPan(data);
  } else if (options.onMiddlePan && button === MouseButton.Middle) {
    options.onMiddlePan(data);
  } else if (options.onRightPan && button === MouseButton.Right) {
    options.onRightPan(data);
  }
};

export const useMouse = (ref: ElementRef, options: Options) => {
  const [state, setState] = useState<MouseState>({
    doubleClickTimeout: undefined,
    event: undefined,
    gesture: undefined,
    lastEventEnd: new Date().getTime()
  });
  const handleStart = useCallback(
    (e: MouseEvent) => {
      stopEvent(e);
      state.event = createMouseGestureEvent(e);
      state.gesture = createMouseGesture(state.event);
      setState(state);
    },
    [options]
  );
  const handleMove = useCallback(
    (e: MouseEvent) => {
      stopEvent(e);
      // only track movement when a previous event is registered
      if (state.event !== undefined) {
        const previousEvent = state.event;
        const previousGesture = state.gesture;
        state.event = createMouseGestureEvent(e, previousEvent);
        state.gesture = findCorrectGesture(state.event, previousEvent, previousGesture);
        state.doubleClickTimeout = activateCallbacks(state, options, false);
        setState(state);
      }
    },
    [options]
  );
  const handleEnd = useCallback(
    (e: MouseEvent) => {
      stopEvent(e);
      state.doubleClickTimeout = activateCallbacks(state, options, true);
      state.event = undefined;
      state.gesture = undefined;
      state.lastEventEnd = new Date().getTime();
      setState(state);
    },
    [options]
  );
  const handleWheel = useCallback(
    (e: WheelEvent) => {
      if (options.onWheel) {
        options.onWheel({ deltaX: e.deltaX, deltaY: e.deltaY });
      }
    },
    [options]
  );
  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("contextmenu", disableContextMenu, false);
      ref.current.addEventListener("mousedown", handleStart, false);
      ref.current.addEventListener("mousemove", handleMove, false);
      ref.current.addEventListener("mouseup", handleEnd, false);
      ref.current.addEventListener("wheel", handleWheel, false);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener("contextmenu", disableContextMenu, false);
        ref.current.removeEventListener("mousedown", handleStart, false);
        ref.current.removeEventListener("mousemove", handleMove, false);
        ref.current.removeEventListener("mouseup", handleEnd, false);
        ref.current.removeEventListener("wheel", handleWheel, false);
      }
    };
  }, [options]);
};
