import { useCallback } from "react";
import { useDispatch } from "redux-react-hook";
import { ToggleName } from "../../settings/enums/ToggleName";
import { useToggles } from "../../settings/hooks/selectorHooks";
import * as actions from "../actions";

export const useToggle = (toggleName: ToggleName): [boolean, VoidFunction] => {
  const dispatch = useDispatch();
  const isToggled = useToggles()[toggleName];
  const toggle = useCallback(() => dispatch(actions.toggle({ toggleName })), [toggleName]);
  return [isToggled, toggle];
};
