import { fork, take } from "redux-saga/effects";
import { isType } from "typescript-fsa";
import * as actions from "./actions";
import { createSaveGame } from "./sagas/createSavegame";
import { loadSavegame } from "./sagas/loadSavegame";
import { syncCache } from "./sagas/syncCache";

export function* saga() {
  yield fork(loadSavegame);
  while (true) {
    const action = yield take([
      actions.createSavegame.started.type,
      actions.syncCache.started.type
    ]);

    if (isType(action, actions.createSavegame.started)) {
      yield fork(createSaveGame, action.payload);
    }

    if (isType(action, actions.syncCache.started)) {
      yield fork(syncCache);
    }
  }
}
