import findIndex from "lodash/findIndex";
import React, {
  KeyboardEvent,
  MouseEvent,
  ReactText,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { useIsMobile } from "../../../measuring/hooks/selectorHooks";
import { useColorStrings } from "../../../settings/hooks/colorHooks";
import { KeyName } from "../../../userInput/enums/KeyName";
import { useActiveControl } from "../../../userInput/hooks/useActiveControl";
import { useTrigger } from "../../../userInput/hooks/useTrigger";
import { ColorName } from "../../enums/ColorName";
import { SelectOption, Setter } from "../../types";
import { joinStrings } from "../../utils/joinStrings";
import { stopEvent } from "../../utils/stopEvent";
import Options from "./Options";
import Selector from "./Selector";
import "./styles.css";

export interface Props {
  className?: string;
  id: string;
  onSubmit?: Setter<number | string>;
  options: SelectOption[];
  value?: string | number;
}

const indexOf = (options: SelectOption[], value?: ReactText) => {
  const index = findIndex(options, ["value", value]);
  return index === -1 ? 0 : index;
};

const Select: React.FunctionComponent<Props> = ({ className, id, onSubmit, options, value }) => {
  const [activeColor, passiveColor, expandedColor, backgroundColor] = useColorStrings(
    ColorName.Primary,
    ColorName.Text,
    ColorName.Secondary,
    ColorName.Background
  );
  const isMobile = useIsMobile();
  const selectElement = useRef<null | HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [highlighted, setHighlighted] = useState(0);
  const [selected, setSelected] = useState<undefined | SelectOption>(undefined);
  const [activeId, setActive, changeSettings] = useActiveControl();
  const isActive = id === activeId;
  const activate = useCallback(() => setActive(id), [id]);
  const deactivate = useCallback(() => setActive(), []);
  const lock = useCallback(() => changeSettings({ isLocked: true }), []);
  const unlock = useCallback(() => changeSettings({ isLocked: false }), []);
  const [isTriggered, trigger] = useTrigger();
  const reset = useCallback(() => {
    const currentIndex = indexOf(options, value);
    setSelected(options[currentIndex]);
    setHighlighted(currentIndex);
  }, [options, setSelected, setHighlighted, value]);
  const end = useCallback(() => {
    unlock();
    reset();
    setIsExpanded(false);
    document.removeEventListener("click", end);
  }, [reset, setIsExpanded, unlock]);
  const start = useCallback(() => {
    document.addEventListener("click", end, { once: true });
    lock();
    setIsExpanded(true);
  }, [end, lock, setIsExpanded]);
  const submit = useCallback(
    (text: ReactText) => {
      if (onSubmit) {
        trigger(id, () => onSubmit(text));
      }
      end();
    },
    [end, id, onSubmit, trigger]
  );
  const handleElementKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      const { key, shiftKey } = e;
      if (isExpanded) {
        if (key === KeyName.ArrowLeft || key === KeyName.Escape) {
          end();
        } else if (key === KeyName.Enter || key === KeyName.ArrowRight || (key as string) === " ") {
          submit(options[highlighted].value);
        } else if (key === KeyName.ArrowDown || key === KeyName.Tab) {
          setHighlighted(highlighted < options.length - 1 ? highlighted + 1 : 0);
        } else if (key === KeyName.ArrowUp || (key === KeyName.Tab && shiftKey)) {
          setHighlighted(highlighted > 0 ? highlighted - 1 : options.length - 1);
        }
        stopEvent(e);
      } else if (key === KeyName.Enter || key === KeyName.ArrowRight || (key as string) === " ") {
        start();
        stopEvent(e);
      }
    },
    [end, highlighted, isExpanded, options, setHighlighted, start, submit]
  );
  const handleElementClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      start();
      stopEvent(e);
    },
    [start]
  );
  useEffect(() => {
    if (selectElement.current && isActive) {
      selectElement.current.focus();
    }
  });
  useEffect(() => {
    reset();
  }, [isMobile, options]);
  return (
    <div
      className={joinStrings(["select", className])}
      id={id}
      onKeyDown={isActive ? handleElementKeyDown : undefined}
      onMouseEnter={activate}
      onMouseLeave={deactivate}
      onClick={isActive && !isExpanded ? handleElementClick : undefined}
      ref={selectElement}
      style={{
        borderColor:
          isTriggered(id) || isExpanded
            ? expandedColor
            : isMobile || !isActive
            ? passiveColor
            : activeColor
      }}
      tabIndex={0}
    >
      <Selector passiveColor={passiveColor} selected={selected} />
      <Options
        backgroundColor={backgroundColor}
        borderColor={expandedColor}
        onOptionClick={submit}
        onOptionHover={setHighlighted}
        highlighted={highlighted}
        isExpanded={isExpanded}
        options={options}
        textColor={passiveColor}
      />
    </div>
  );
};

export default Select;
