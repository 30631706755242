import { Size } from "../../core/types";
import { BREAKPOINT_DESKTOP, BREAKPOINT_TABLET, BREAKPOINT_WIDE_DESKTOP } from "../constants";
import { FormFactor } from "../enums/FormFactor";

export const calculateFormFactor = ({ width }: Size): FormFactor => {
  if (width < BREAKPOINT_TABLET) {
    return FormFactor.Phone;
  } else if (width >= BREAKPOINT_TABLET && width < BREAKPOINT_DESKTOP) {
    return FormFactor.Tablet;
  } else if (width >= BREAKPOINT_DESKTOP && width < BREAKPOINT_WIDE_DESKTOP) {
    return FormFactor.Desktop;
  } else {
    return FormFactor.WideDesktop;
  }
};
