import { Action } from "redux";
import { isType } from "typescript-fsa";
import { getWorld } from "./actions";
import { State } from "./types";

const initialState: State = {
  isGettingWorld: false
};

export const reducer = (state: State = initialState, action: Action): State => {
  if (isType(action, getWorld.started)) {
    return {
      ...state,
      isGettingWorld: true
    };
  }

  if (isType(action, getWorld.failed) || isType(action, getWorld.done)) {
    return {
      ...state,
      isGettingWorld: false
    };
  }

  return state;
};
