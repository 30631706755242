import { delay, fork, put, take } from "redux-saga/effects";
import { isType } from "typescript-fsa";
import * as actions from "./actions";
import { NOTIFICATION_DURATION } from "./constants";
import { Notification } from "./types";

function* removeNotification({ id }: Notification) {
  yield delay(NOTIFICATION_DURATION);
  yield put(actions.removeNotification({ id }));
}

export function* saga() {
  while (true) {
    const action = yield take(actions.addNotification);
    if (isType(action, actions.addNotification)) {
      yield fork(removeNotification, action.payload.notification);
    }
  }
}
